import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  canActivate,
  customClaims,
} from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParsedToken } from 'firebase/auth';

const redirectAdminHome = () =>
  pipe(
    customClaims,
    map((claims: ParsedToken) => {
      return claims.admin === true ? claims.admin : ['login'];
    })
  );

// export const redirectAdminTo = (redirect: any[]) =>
//   pipe(hasCustomClaim('admin'), redirectUnauthorizedTo(['login'], redirectLoggedInTo(['home']));
// const redirectAdminHome = () => redirectAdminTo(['home']);

// const redirectAdminHome = () => adminOnly()

// const redirectAdminHome = () => map(user => user ? true : ['login']);

// const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersPageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule)
      //...canActivate(redirectAdminHome),
  },
  {
    path: 'function-logs',
    loadChildren: () =>
      import('./function-logs/function-logs.module').then(
        (m) => m.FunctionLogsPageModule
      ),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectAdminHome },
  },
  {
    path: 'tasksets',
    loadChildren: () =>
      import('./tasksets/tasksets.module').then((m) => m.TasksetsPageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'content/:contentType',
    loadChildren: () =>
      import('./content/content.module').then((m) => m.ContentPageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./chat/chat.module').then((m) => m.ChatPageModule),
      ...canActivate(redirectAdminHome),
  },
  {
    path: 'challenges',
    loadChildren: () => import('./challenges/challenges.module').then( m => m.ChallengesPageModule),
    ...canActivate(redirectAdminHome)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then( m => m.StatisticsPageModule),
    ...canActivate(redirectAdminHome)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule),
    ...canActivate(redirectAdminHome)
  },
  {
    path: 'offers/offer/:name',
    loadChildren: () => import('./offer/offer.module').then( m => m.OfferPageModule),
    ...canActivate(redirectAdminHome)
  },
  {
    path: 'offers/offer/:name/purchases/:userid',
    loadChildren: () => import('./offer-purchases/offer-purchases.module').then( m => m.OfferPurchasesPageModule)
  },
  {
    path: 'cloud-tasks',
    loadChildren: () => import('./cloud-tasks/cloud-tasks.module').then( m => m.CloudTasksPageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then( m => m.ProgramsPageModule),
    ...canActivate(redirectAdminHome),
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'open-ai',
    loadChildren: () => import('./open-ai/open-ai.module').then( m => m.OpenAIPageModule)
  },
  {
    path: 'approvals',
    loadChildren: () => import('./approvals/approvals.module').then( m => m.ApprovalsPageModule),
    data: { type: 'approvals' }
  },
  {
    path: 'approvals/entry/:userId/:docId',
    loadChildren: () => import('./approve/approve.module').then( m => m.ApprovePageModule),
    data: { type: 'approvals' }
  },
  {
    path: 'reviews',
    loadChildren: () => import('./approvals/approvals.module').then( m => m.ApprovalsPageModule),
    data: { type: 'reviews' }
  },
  {
    path: 'reviews/entry/:userId/:docId',
    loadChildren: () => import('./approve/approve.module').then( m => m.ApprovePageModule),
    data: { type: 'reviews' }
  },
  {
    path: 'thread/:userId/:threadId',
    loadChildren: () => import('./thread/thread.module').then( m => m.ThreadPageModule)
  },
  {
    path: 'prompting',
    loadChildren: () => import('./prompting/prompting.module').then( m => m.PromptingPageModule)
  },
  {
    path: 'prompt-status/:userId/:key',
    loadChildren: () => import('./prompt-status/prompt-status.module').then( m => m.PromptStatusPageModule)
  },
  {
    path: 'chat-id-summary/:userId/:chatId',
    loadChildren: () => import('./chat-id-summary/chat-id-summary.module').then( m => m.ChatIdSummaryPageModule)
  },
  {
    path: 'ledgers',
    loadChildren: () => import('./ledgers/ledgers.module').then( m => m.LedgersPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'groups/ledger/:groupDocId',
    loadChildren: () => import('./group-ledger/group-ledger.module').then( m => m.GroupLedgerPageModule)
  },
  {
    path: 'groups/members/:groupDocId',
    loadChildren: () => import('./group-members/group-members.module').then( m => m.GroupMembersPageModule)
  },
  {
    path: 'test-runs',
    loadChildren: () => import('./test-runs/test-runs.module').then( m => m.TestRunsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      //relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
