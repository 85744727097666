import { Inject, Injectable, inject } from '@angular/core'
import { BehaviorSubject, debounceTime, filter, firstValueFrom, map, retry, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs'
import { AppSource, ChallengeSpec, Conversation, DEFAULT_CHALLENGE_SEED_COST_KEY, DEFAULT_CHAT_SEED_COST_KEY, EntrySpec, PortalOfferSpec, PricingSpec, ProgramCategory, ProgramSpec, PromptGuide, Sequence, addstrISO, evaljson, nowstrISO, range } from '@cheaseed/node-utils'
import { FirebaseService } from './firebase.service'
import { HIDDEN } from '@cheaseed/node-utils'
import { Preferences } from '@capacitor/preferences'
import { HttpClient } from '@angular/common/http'
import { Platform } from '@ionic/angular'
import { add } from 'date-fns'
import { AuthService } from './auth.service'

export const CONTENT_CONFIG = 'content/config'
const FULLCONTENT = 'FullContent'
const FULLCONTENT_KEYS = [
  'Global',
  'EntrySpec',
  'ActionSpec',
  'AttributeSpec',
  'AttributeList',
  'Challenge',
  'Conversation',
  'Path',
  'Topic',
  'Sequence',
  'TaskSet',
  // 'Carousel',
  'Points',
  // 'Program',
  // 'ProgramCategory',
  'Persona'
]
const FULLCONTENT_JSON_FILE = `assets/content/${FULLCONTENT}.json`
const FULLCONTENT_TIMESTAMP_FILE = `assets/content/${FULLCONTENT}Timestamp.json`

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private firebase = inject(FirebaseService)
  private auth = inject(AuthService)
  
  private contentRoot:string
  entrySpecs: EntrySpec[] = []
  actionSpecs: any[] = []
  attributeListMap: Map<string, string[]> = new Map()
  attributeSpecs: any[] = []
  entrySpecMap: Record<string, EntrySpec> = {}
  private attributeSpecMap: any = {}
  private defaultAttributeSpecMap: any = {}
  actionMap: Map<string, any> = new Map()
  conversations: Conversation[] = []
  conversationMap: Map<string, Conversation> = new Map()
  topicsMap: Map<string, any> = new Map()
  pathMap: Map<string, any> = new Map()
  pathConversationMap: Map<string, any> = new Map()
  pathCodeMap: Map<string, any> = new Map()
  masterSequence: Sequence[] = []
  learnSequence: Sequence[] = []
  taskSetMap: Map<string, any> = new Map()
  carousels: any[] = []
  programMap: Map<string, ProgramSpec> = new Map()
  programCategoryMap: Map<string, ProgramCategory> = new Map()
  challengeMap: Map<string, ChallengeSpec> = new Map()
  promptGuides: PromptGuide[] = []
  points: any[] = []
  personas: any[] = []
  tileBackgrounds: any
  contentUrls: any
  mediaMap: any = {}
  globals: any = null;
  collectibleTypes: any[]|null = null
  private spinnerMessages: string[] = []
  lastSpinnerMessageTime: Date|null = null
  lastSpinnerMessageIndex = -1

  private loaderSubject = new BehaviorSubject(false)
  loader$ = this.loaderSubject.asObservable()
  isLoaded$ = this.loader$.pipe(filter(loaded => !!loaded))
  globalsLoaded$ = new BehaviorSubject(false)

  // Used only by the admin app
  globals$ = this.firebase.doc$('content/globals')
    .pipe(
      filter(data => !!data),
      map((doc:any) => {
        this.spinnerMessages = doc.strings.filter((g:any) => g.name.startsWith('didyouknow.message')).map((g:any) => g.value)
        return new Map<string, any>(doc.strings.map((item:any) => [item.name, item.value]))
      }),
      shareReplay(1)
    )

  pricingSpecs$ = this.isLoaded$
    .pipe(
      switchMap(() => this.firebase.collection$(`${this.contentRoot}/web/pricing`)),
      debounceTime(300),
      map((specs:PricingSpec[]) => {
        specs.sort((a, b) => ((a.order || 0) > (b.order || 0)) ? 1 : -1)
        specs.forEach(s => {
          s.seedCredits = evaljson(s.seedCredits)
        })
        // console.log(`reloaded ${specs.length} pricing specs`, specs)
        return specs
      }),
      shareReplay(1)
    )

  portalOffers$ = this.isLoaded$
    .pipe(
      // tap(init => console.log('portalOffers$', init)),
      filter(init => !!init),
      switchMap(() => this.firebase.collection$(`${this.contentRoot}/web/portalOffers`)),
      debounceTime(300),
      map(res => res as PortalOfferSpec[]),
      tap(res => console.log(`retrieved ${res.length} portal offer specs`, res)),
      shareReplay(1)
    )

  portalHomeOptions$ = this.isLoaded$
    .pipe(
      switchMap(() => this.firebase.collection$(`${this.contentRoot}/web/portalHomeOptions`)),
      // tap(data => console.log('portalHomeOptions$', data)),
      debounceTime(300),
      map((options:any[]) => {
        options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        return options
      }),
      // shareReplay(1)
    )

    paymentLinks$ = this.isLoaded$
      .pipe(
        switchMap(() => this.firebase.collection$(`${this.contentRoot}/web/paymentLinkOptions`)),
        debounceTime(300),
        map((options:any) => {
          return options
            .filter(o => o.order)
            .toSorted((a, b) => (a.order > b.order) ? 1 : -1)
        }),
        shareReplay(1)
      )    

  contentConfigTimestamp$ = new BehaviorSubject(0)
  
  private localContentTimestamp = 0
  latestReleaseTag = ''
  nextReleaseAvailable$ = new BehaviorSubject<boolean>(false)
  maintenanceMode$ = new BehaviorSubject<boolean>(false)
  
  approvedInAppStores = false

  // private CareerEventTypes = [
  //   { description: "I have a performance review coming up" },
  //   { description: "I just had a performance review" },
  //   { description: "I just got a promotion" },
  //   { description: "I just got passed over for a promotion I wanted" },
  //   { description: "I just got a job offer" },
  //   { description: "I just declined a job offer" },
  //   { description: "I just accepted a job offer" },
  //   { description: "I'm starting a new job" },
  //   { description: "I've decided to leave my current job for advancement (either company or role)" },
  //   { description: "I've decided to stay in my current job but look for a new one" }
  // ]

  private inputSubtypePatterns: any[] = [
    {
      pattern: "Segmented",
      func: (a: { inputType: string }, result: any) => {
        a.inputType = 'SEGMENTED'
      }
    },
    {
      pattern: "^(\\d+)-(\\d+)",
      func: (a: { scaleMin: number; scaleMax: number }, result: string[]) => {
        a.scaleMin = parseInt(result[1])
        a.scaleMax = parseInt(result[2])
      }
    },
    {
      pattern: "Scale(\\d+)-(\\d+)",
      func: (a: { inputType: string; scaleMin: number; scaleMax: number; scaleRange: any[]; scaleLabels: any[]; optionLinks: string | any[] }, result: string[]) => {
        a.inputType = 'SCALE'
        a.scaleMin = parseInt(result[1])
        a.scaleMax = parseInt(result[2])
        a.scaleRange = []
        a.scaleLabels = []
        if (a.optionLinks.length > 0) {
          for (let i = a.scaleMin; i <= a.scaleMax; i++) {
            a.scaleRange.push(i)
            a.scaleLabels.push(i - a.scaleMin < a.optionLinks.length ? a.optionLinks[i - a.scaleMin].description : i)
          }
        }
        else {
          for (let i = a.scaleMin; i <= a.scaleMax; i++) {
            a.scaleRange.push(i)
            a.scaleLabels.push(i)
          }
          if (a.scaleMin === 1 && a.scaleMax === 3) {
            a.scaleRange = [1, 2, 3]
            a.scaleLabels = ["Low", "Med", "High"]   // TODO: pull label names from AttributeSpec optionLinks
          }
        }
      }
    },
    {
      pattern: "List(\\d+)-(\\d+)",
      func: (a: { optionLinks: { name: string; description: number }[] }, result: string[]) => {
        a.optionLinks = range(parseInt(result[1]), parseInt(result[2])).map(i => { return { name: i + "", description: i } })
      }
    },
    {
      pattern: "TimeOfDay(\\d{4})-(\\d{4})",
      func: (a: { optionLinks: { name: string; description: string }[] }, result: string[]) => {
        const opts = range(
          parseInt(result[1]),
          parseInt(result[2]),
          100)
          .map(i => {
            // times enter in 4-digit 24hr time
            const t = (i + "").padStart(4, '0')
            // Handle 0am and 12pm for 2400, both should be 12am
            const desc = (i == 0 || i == 2400) ? "12am" :
              i < 1200
                ? Math.floor(i / 100) + (i % 100 ? ":" + (i % 100) : "") + "am"
                : Math.floor((i - (i < 1300 ? 0 : 1200)) / 100) + (i % 100 ? ":" + (i % 100) : "") + "pm"
            return {
              name: t,
              description: desc
            }
          })
        a.optionLinks = opts
      }
    },
    {
      pattern: "YN",
      func: (a: { optionLinks: { name: string; description: string }[] }, result: any) => {
        a.optionLinks = [{ name: "Yes", description: "Yes" }, { name: "No", description: "No" }]
      }
    },
    {
      pattern: "Actions",
      func: (a: { optionLinks: any[] }, result: any) => { a.optionLinks = this.getActions() }
    },
    {
      pattern: "Commitments",
      func: (a: { optionLinks: any[] }, result: any) => { a.optionLinks = this.getActions() }
    }
  ]

  constructor(
    @Inject('environment') private environment: any,    
    private platform: Platform,
    private http: HttpClient
  ) {
      // Remove trailing alphanumerics to enable testing with content for mock release tags like v0.85a
      const releaseTag = this.environment.releaseTag.replace(/([0-9]+)([a-zA-Z0-9]*)$/, '$1')
      this.contentRoot = `${CONTENT_CONFIG}/${releaseTag}`
    }

    subscribeToContentConfig() {
      // console.log("subscribeToContentConfig")
      this.firebase.doc$(CONTENT_CONFIG)
        .pipe(
          filter(data => !!data),
          debounceTime(100),
          withLatestFrom(this.maintenanceMode$, this.contentConfigTimestamp$, this.loader$))
        .subscribe(([doc, maintenanceMode, contentConfigTimestamp, loaded]) => {
            // console.log(`${CONTENT_CONFIG} checking`, { loaded, contentConfigTimestamp, new: doc.updatedAt.seconds })
            if (!loaded || contentConfigTimestamp !== doc.updatedAt.seconds) {
              // only update if first time or changed
              console.log(`${CONTENT_CONFIG} change detected`, doc.updatedAt.seconds)              
              this.reload(true)
            }
            if (doc.latestReleaseTag !== this.environment.releaseTag && !doc.maintenanceMode) {
              console.log(`${CONTENT_CONFIG} release change detected ${doc.latestReleaseTag}, app release is ${this.environment.releaseTag}`)
              this.nextReleaseAvailable$.next(true)
            }
            if (doc.maintenanceMode !== maintenanceMode) {
              console.log(`${CONTENT_CONFIG} maintenance mode change detected ${doc.maintenanceMode}`)
              this.maintenanceMode$.next(doc.maintenanceMode)
            }
          })
    }

  /****************
   * 
   * Firebase content loading
   * 
   ****************/

  // Check if we need to load content from bundled file into Local Storage
  //    if localTimestamp in localStorage is same as bundled timestamp, skip
  //    if localTimestamp is missing in localStorage, load content and cache
  //    if localTimestamp in localStorage is less than bundled timestamp, load content and refresh cache
  async checkBundledContent() {
    const timefile: any = await firstValueFrom(this.http.get(FULLCONTENT_TIMESTAMP_FILE)) // minimal cost local read
    const secs = Math.floor((new Date(timefile.timestamp)).getTime() / 1000)
    const timestampKey = `${FULLCONTENT}/time`
    const { value } = await Preferences.get({ key: timestampKey })
    const localTimestamp = value ? parseInt(value) : 0
    this.localContentTimestamp = localTimestamp
    console.log("checkBundledContent", `bundled timestamp: ${secs} local timestamp: ${localTimestamp}`)
    if (!localTimestamp || localTimestamp < secs) {
      // load content and cache
      try {
        console.log("checkBundledContent", "loading from assets")
        const result:any = await firstValueFrom(this.http.get(FULLCONTENT_JSON_FILE))
        await this.updateLocalStorageFullContent(result, FULLCONTENT_KEYS, secs)
      }
      catch (err) {
        console.error("checkBundledContent", `Error loading ${FULLCONTENT_JSON_FILE}: ${err}`)
      }
    }
  }

  async loadGlobals() {
    // We load whatever is present in local storage, avoiding network calls
    // We can be certain that local storage contains something based on checkBundledContent() behavior
    const result = await this.getLocalStorageContent('Global')
    if (result) {
      this.prepareGlobals(result)
      this.globalsLoaded$.next(true)
    }
  }

  /**
   * Load globals from firestore. Used by the portal currently
   * TODO - change the web app to use this as well and remove
   * the dependency on the bundled FullContent.json and FullContentTimestamp.json
   * there as well
   */
  async loadGlobalsFromFirestore() {
    const path = 'content/globals'
    console.log(`loadGlobalsFromFirestore`, path)
    const result = await this.firebase.getFirestoreDoc(path)
    this.prepareGlobals(result.strings)
    this.globalsLoaded$.next(true)
  }

  async reload(useFull = true) {
    this.contentUrls = await this.firebase.getFirestoreDoc(`${this.contentRoot}/downloadUrls`)
    await this.updateContentConfig()
    await this.reloadFullContent()
    this.publishLoaded()
  }

  async updateContentConfig() {
    const configdoc = await firstValueFrom(this.firebase.doc$(CONTENT_CONFIG))
    this.setContentConfigTimestamp(configdoc)
  }

  async loadForWeb() {
    this.contentUrls = await this.firebase.getFirestoreDoc(`${this.contentRoot}/downloadUrls`)
    this.prepareGlobals(await this.getContent('Global'))
    this.prepareEntrySpecs(await this.getContent('EntrySpec'))
    this.prepareAttributeSpecs(await this.getContent('AttributeSpec'))
    this.prepareConversations(await this.getContent('Conversation'))
    this.preparePersonas(await this.getContent('Persona'))
    this.loadMediaMap()
    await this.updateContentConfig()
    this.publishLoaded()
  }

  async getContent(type: string) {
    return await this.getFirebaseContent(type, this.contentUrls[type])
  }

  getStorageKeys() {
    return FULLCONTENT_KEYS
  }

  async reloadFullContent() {
    const content: any = await this.getFullContent('FullContent', this.contentUrls['FullContent'], this.getStorageKeys())
    // console.log("reloadFullContent", content.length)
    await this.loadMediaMap()

    // prepareGlobals is needed when a content change is detected.
    // it gets called twice during the initial content download; once
    // before the user is signed in and once when initializeSession 
    // loads all the content
    if (this.environment.appSource === AppSource.Portal)
      this.loadGlobalsFromFirestore()
    else
      this.prepareGlobals(content.Global)
    
    this.prepareEntrySpecs(content.EntrySpec)
    this.prepareActionSpecs(content.ActionSpec)
    this.prepareAttributeSpecs(content.AttributeSpec)
    this.prepareAttributeList(content.AttributeList)
    this.prepareChallenges(content.Challenge)
    this.prepareConversations(content.Conversation)
    this.preparePaths(content.Path)
    this.prepareTopics(content.Topic)
    this.prepareSequences(content.Sequence)
    this.prepareTaskSets(content.TaskSet)
    // this.prepareCarousels(content.Carousel)
    this.points = content.Points
    this.preparePersonas(content.Persona)
    // this.preparePrograms(content.Program)
    // this.prepareProgramCategories(content.ProgramCategory)
    await firstValueFrom(this.getPromptGuides())
  }

  async getFullContent(file: string, url: string, keys: string[]) {
    const timestampKey = `${file}/time`
    if (!this.localContentTimestamp) {
      console.log('getFullContent', 'detected no localContentTimestamp')
      const { value } = await Preferences.get({ key: timestampKey })
      this.localContentTimestamp = value ? parseInt(value) : 0
    }
    const configTimestamp = await firstValueFrom(this.contentConfigTimestamp$)
    console.log('getFullContent', `localContentTimestamp set to ${this.localContentTimestamp}; contentConfigTimestamp = ${configTimestamp}`)

    if (!this.localContentTimestamp || this.localContentTimestamp < configTimestamp) {
      // Load from URL
      try {
        const json = await this.getRemoteJson(url)
        console.log('getFullContent', `loaded from ${url}`)
        await this.updateLocalStorageFullContent(json, keys, configTimestamp)
        return json
      }
      catch (err) {
        console.error(`Error loading from ${url}`, err)
        console.error('Falling back to local storage')
      }
    }
    try {
      const content:any = {}
      for (const k of keys) {
        const result = await this.getLocalStorageContent(k)
        content[k] = result
      }
      return content
    }
    catch (err) {
      console.error('Error loading from cache', err)
      throw err
    }
  }

  async getRemoteJson(url: string) {
    return await firstValueFrom(
      this.http.get(url)
      .pipe(
        retry({
          count: 3,
          delay: 1000
        }))
    )
  }

  async updateLocalStorageFullContent(json: any, keys: string[], timestamp: number) {
    const timestampKey = `${FULLCONTENT}/time`
    await Preferences.set({ key: timestampKey, value: `${timestamp}` })
    this.localContentTimestamp = timestamp
    console.log('updateLocalStorageFullContent', `Set ${timestampKey} to ${timestamp}`)
    for (const k of keys) {
      const data = json[k]
      await Preferences.set({ key: k, value: JSON.stringify(data) })
      console.log(`wrote to local storage cache ${k}: ${data?.length || Object.keys(data || {}).length} items`)
    }
  }

  async getLocalStorageContent(key: string) {
    let result
    const { value } = await Preferences.get({ key })
    if (value) {
      result = JSON.parse(value)
      console.log(`loaded from local storage cache ${key} ${result?.length || Object.keys(result).length} items`)
    }
    return result
  }

  async getFirebaseContent(file: string, url: string, cache = true) {
    const base:string = (url ? file : file.split('/').pop()) as string
    const timestampKey = `${base}/time`
    if (!this.localContentTimestamp) {
      const { value } = await Preferences.get({ key: timestampKey })
      this.localContentTimestamp = parseInt(value as string)
    }
    const configTimestamp = await firstValueFrom(this.contentConfigTimestamp$)
    if (this.localContentTimestamp === configTimestamp) {
      const result = await this.getLocalStorageContent(base)
      if (result)
        return result
    }
    if (!url)
      url = await this.firebase.getDownloadURL(file)
    const json = await this.getRemoteJson(url)
    // TODO: Check for error
    console.log("getFirebaseContent", `loaded from ${url}`)
    await Preferences.set({ key: timestampKey, value: `${configTimestamp}` })
    this.localContentTimestamp = 0
    if (cache) 
      await Preferences.set({ key: base, value: JSON.stringify(json) })
    
    return json
  }

  async getContentConfigTimestamp() {
    let doc: any
    try {
      //doc = await firstValueFrom(this.firebase.doc$(CONTENT_CONFIG))
      doc = await firstValueFrom(this.firebase.docWithoutAuth$(CONTENT_CONFIG))
    }
    catch (e) {
      console.error('getContentConfigTimestamp', e)
    }
    const secs = this.setContentConfigTimestamp(doc)
    this.approvedInAppStores = !!doc.approved
    this.latestReleaseTag = doc.latestReleaseTag  // need for old app check
    return secs
  }

   isSeedsEnabled() {
    return false // disabled for portal
    // TODO - inject in app.config
  }

  setContentConfigTimestamp(doc: { updatedAt: { seconds: string } }) {
    const secs = parseInt(doc.updatedAt.seconds)
    this.contentConfigTimestamp$.next(secs)
    console.log("setContentConfigTimestamp set ", secs)
    return secs
  }

  publishLoaded() {
    this.loaderSubject.next(true)
  }

  unpublishLoaded() {
    this.loaderSubject.next(false)
  }

  async getTilesContent() {
    if (!this.tileBackgrounds)
      this.tileBackgrounds = await this.firebase.getFirestoreDoc(`${this.contentRoot}/tileBackgrounds`)
    return this.tileBackgrounds
  }

  async prepareConversations(list: any[]) {
    // Parse Conversations
    const convs = list
      .filter(c => c.name)
      .map(c => new Conversation(c))   
    convs.sort((a, b) => a.title > b.title ? 1 : -1)
    this.conversationMap = new Map(convs.map(c => [c.name, c]))
    this.conversations = convs
    // console.log('prepareConversations', `loaded ${convs.length} conversations`, convs)
  }

  getConversationNamed(name: string) {
    return this.conversationMap.get(name)
  }

  preparePaths(paths: any[]) {
    // Parse paths
    for (const p of paths) {
      p.id = p.name
      p.conversations = p.conversationLinks.map((c: { name: string }) => this.getConversationNamed(c.name))
      p.hasPopulatedChats = false
      for (const c of p.conversations) {
        // Each conversation knows its series
        c.series = c.series.add(p.id)
        // if (c.statements.length > 0)
        p.hasPopulatedChats = true
      }
      // Assign single topic
      p._topic = p.topic[0]
      // Relic from when there were multiple topics per series
      p.topic.forEach((t: { name: any }) => {
        for (const c of p.conversations) {
          // Each conversation must know its topics
          c.topics = c.topics.add(t.name)
        }
      })
    }
    this.pathMap = new Map(paths.map(p => [p.id, p]))
    this.pathCodeMap = new Map(paths.map(p => [p.code, p]))
    this.pathConversationMap = new Map(paths.map(p => [p.id, p.conversations]))
  }

  prepareTopics(topics: any[]) {
    // Parse topics
    for (const t of topics)
      t.path = t.path.map((p: { name: string }) => this.pathMap.get(p.name))
    this.topicsMap = new Map<string, any>(topics.map(t => [t.name, t]))
  }

  async prepareSequences(sequences: any[]) {
    // Parse sequences
    // sequences = sequences.map(s => new Sequence(s))
    sequences.forEach(s => { s.id = s.name })
    let seq = sequences.filter(item => item.type === 'Master')
    seq.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.masterSequence = seq
    seq = sequences.filter(item => item.type === 'Learn')
    seq.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.learnSequence = seq
    // console.log("learnSequence", seq)
  }

  prepareTaskSets(tasksets: any[]) {
    this.taskSetMap = new Map<string, any>(tasksets.map(ts => [ts.taskSetName, ts]))
    // console.log("taskSetMap", this.taskSetMap)
  }

  async prepareCarousels(carousels: any[]) {
    // Parse Carousels
    carousels.sort((a, b) => (a.order > b.order) ? 1 : -1)
    carousels.forEach(c => {
      c.seriesList = c.paths.map((p: { name: string }) => this.pathMap.get(p.name))
    })
    this.carousels = carousels
  }

  preparePersonas(list: any[]) {
    const personas = list.filter(p => p.order && p.assignIf)
    personas.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.personas = personas
  }

  prepareChallenges(list: any[]) {
    const challenges = list.map(c => new ChallengeSpec(c))
    challenges.sort((a:any, b:any) => a.title > b.title ? 1 : -1)
    this.challengeMap = new Map<string, ChallengeSpec>(challenges.map(c => [c.name, c]))
  }

  preparePrograms(programs: any[]) {
    programs = programs.map(p => new ProgramSpec(p)).filter(s => !s.isHidden())
    programs.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.programMap = new Map<string, ProgramSpec>(programs.map(p => [p.name, p]))
  }

  prepareProgramCategories(data: any[]) {
    const programCategories = data.map(pc => {
      pc.programs = pc.programs.map((p: { name: string }) => this.programMap.get(p.name)).filter((p: any) => p)
      return new ProgramCategory(pc)
    })
    programCategories.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.programCategoryMap = new Map<string, ProgramCategory>(programCategories.map(pc => [pc.name, pc]))
    // console.log(this.programCategoryMap)
  }

  sort(list: any, fld: string, direction: string) {
    const [val1, val2] = direction === 'asc' ? [-1, 1] : [1, -1];
    return list.sort((a: { [x: string]: number }, b: { [x: string]: number }) => a[fld] < b[fld] ? val1 : a[fld] > b[fld] ? val2 : 0);
  }

  prepareEntrySpecs(entrySpecs: any[]) {
    const map:Record<string, EntrySpec> = {}
    const specs = entrySpecs.map(e => new EntrySpec(e))
    for (const item of specs) {
      item.id = item.name
      item.attributeSpecs = []
      map[item.name] = item
    }
    this.entrySpecs = specs
    this.entrySpecMap = map
  }

  prepareAttributeSpecs(attributeSpecs: any[]) {
    this.attributeSpecMap = {}
    this.defaultAttributeSpecMap = {}
    this.attributeSpecs = attributeSpecs
    for (const item of attributeSpecs) {
      item.id = item.name
      this.attributeSpecMap[item.name] = item
      if (item.behaviors?.includes("defaultOnFirstAuth"))
        this.defaultAttributeSpecMap[item.id] = item
      if (item.entrySpec)
        this.entrySpecMap[item.entrySpec.name].attributeSpecs?.push(item)
    }  
  
    // Finalize attribute options and actions
    this.entrySpecs.forEach(e => {
      this.sort(e.attributeSpecs, "order", "asc")
      e.attributeSpecs?.forEach(a => {
        if (a.inputType === 'OPTIONS' && a.inputSubtype)
          this.configureAttributeSpecOptions(a)
        a.behaviors = a.behaviors || []
      })
      e.actionLinks = e.actionLinks || []
      for (const a of e.actionLinks)
        a.icon = this.getAction(a.actionEntrySpec?.name)?.icon
      e.behaviors = e.behaviors || []
    })
    // console.log("firebase metadata", this.entrySpecs, this.actionSpecs)
  }

  updateAttributeSpecsFromAirtable(incoming: any[]) {
    incoming.forEach(rec => {
      const spec = this.attributeSpecMap[rec.Name]
      if (spec) {
        spec.inputType = rec.InputType
        if (rec.InputTypeParams) {
            try {
                spec.inputTypeParams = JSON.parse(rec.InputTypeParams)  
            }
            catch (e) {
                console.error("Error parsing InputTypeParams", rec.Name, rec.InputTypeParams, e)
            }
        }
        spec.behaviors = rec.Behaviors
        spec.question = rec.Question
        spec.shortQuestion = rec["Short Question"]
        spec.placeHolder = rec.PlaceHolder
        spec.purpose = rec.Purpose
        spec.benefit = rec.Benefit
        // Skip handling of OptionLinks for now
        this.attributeSpecMap[rec.Name] = spec
        if (spec.behaviors?.includes("defaultOnFirstAuth"))
          this.defaultAttributeSpecMap[rec.Name] = spec
      }
    })
  }

  prepareAttributeList(attrListItems: any[]) {
    attrListItems.sort((a, b) => (a.order > b.order) ? 1 : -1)
    const attrListMap = new Map<string, any[]>()
    for (const attr of attrListItems) {
      const items = attrListMap.get(attr.listName) || []
      items.push(attr)
      attrListMap.set(attr.listName, items)
    }
    this.attributeListMap = attrListMap
  }

  getAttributeListNamed(name: string): string[]|undefined {
    return this.attributeListMap.get(name)
  }

  prepareActionSpecs(actionSpecs: any[]) {
    this.actionSpecs = actionSpecs.filter(a => a.actionEntrySpec) // filter out incompleted Actions
    this.actionSpecs.forEach((a: { id: any; name: any }) => { a.id = a.name })
    this.actionMap = new Map(this.actionSpecs.map((a: { actionEntrySpec: { name: any } }) => [a.actionEntrySpec.name, a]))
  }

  getAction(name: any): any {
    const act = this.actionMap.get(name)
    return act
  }

  getActions(): any[] {
    return this.actionSpecs
  }

  configureAttributeSpecOptions(a: { inputSubtype: string }) {
    // console.log("Checking inputSubtype ", a.inputSubtype)
    for (const item of this.inputSubtypePatterns) {
      const func = item.func,
        regex = new RegExp(item.pattern, "i");
      if (regex.test(a.inputSubtype)) {
        const result = a.inputSubtype.match(regex)
        // console.log("Result ", result)
        func(a, result)
        break;
      }
    }
  }

  attributeHasBehavior(a: { behaviors: string | any[] }, b: any) {
    return a.behaviors?.includes(b)
  }

  getDefaultOption(attr: { optionLinks: any[] }) {
    return attr.optionLinks.find((o: { behaviors: string | string[] }) => o.behaviors?.includes('isDefault'))
  }

  async loadMediaMap() {
    this.mediaMap = await this.firebase.getFirestoreDoc(`${this.contentRoot}/mediaUrls`)
    console.log("loadMediaMap size", Object.keys(this.mediaMap).length)
  }

  getMedia(id: string) {
    return this.mediaMap[id]
  }

  getTakeawayUrl(id: string) {
    return this.getMedia(`cropped_tiles/${id}.png`)
  }

  getFullTakeawayUrl(id: string) {
    const str = this.getMedia(`tiles/${id}.png`)
    console.log(`Take away URL = ${str}`)
    return str
  }

  getGlobal(key: string) {
    return this.globals ? this.globals[key] : null
  }

  private getAppStoreURL() {
    return this.getGlobal('appstore.url')
  }

  private getPlayStoreURL() {
    return this.getGlobal('playstore.url')
  }

  getAppUpdateURL() {
    return this.platform.is('ios') ? this.getAppStoreURL() 
            : this.platform.is('android') ? this.getPlayStoreURL() : null
  }

  prepareGlobals(list: any[]) {
    this.globals = Object.fromEntries(list.map(g => [g.name, g.value]))
    this.spinnerMessages = list.filter(g => g.name.startsWith('didyouknow.message')).map(g => g.value)
    return this.globals
  }

  currentSpinnerMessage() {
    // Compute the current spinner index to use based on the current 10-second period
    const now = new Date()
    if (!this.lastSpinnerMessageTime || now > add(this.lastSpinnerMessageTime as Date, { seconds: 10 })) {
      this.lastSpinnerMessageIndex = Math.floor(this.spinnerMessages.length * Math.random())
      this.lastSpinnerMessageTime = now
    }
    return this.spinnerMessages[this.lastSpinnerMessageIndex] 
  }

  getEntrySpec(entry: string) {
    // console.log('in getEntrySpec', this.entrySpecMap, this.entrySpecMap[entry])
    return this.entrySpecMap[entry]
  }

  getEntrySpecAttributes(entry: string) {
    const e = this.getEntrySpec(entry)
    if (!e)
      console.error("Found no entry named: " + entry)
    return e.attributeSpecs
  }

  getAttributeSpec(attr: string) {
    return this.attributeSpecMap ? this.attributeSpecMap[attr] : null
  }

  getAllAttributeSpecs() {
    return Object.values(this.attributeSpecMap) as any[]
  }

  getCollectibleTypes() {
    if (!this.collectibleTypes) {
      this.collectibleTypes = this.entrySpecs.filter((spec: any) => spec.behaviors?.includes("Collectible"))
    }
    return this.collectibleTypes
  }

  isReportableAttributeSpec(key: string) {
    return this.attributeSpecMap[key]?.behaviors?.includes("reportForAnalytics")
  }

  getFirstAuthTaskSets() {
    return Array.from(this.taskSetMap.values()).filter(ts => ts.onFirstAuth)
  }

  getFollowupTaskSets(id: any) {
    return Array.from(this.taskSetMap.values()).filter(ts => ts.type === 'Followup' && ts.sourceChat === id)
  }

  getTaskSetsOfType(type: string) {
    return Array.from(this.taskSetMap.values()).filter(ts => ts.type === type)
  }

  getTaskSetNamed(taskSetName: string) {
    return this.taskSetMap.get(taskSetName)
  }

  getNextChatsInSeries(series: { conversations: any; type: string }, chatName = null) {
    console.log("getNextChatsInSeries", series, chatName)
    const chats = series.conversations
    if (series.type === 'Ordered') {
      const idx = chatName ? chats.findIndex((c: { name: string }) => c.name === chatName) + 1 : 0
      return chats.slice(idx).filter((c: { behaviors: string | string[] }) => !c.behaviors?.includes(HIDDEN))
    }
    else
      return chats.filter((c: { name: string; behaviors: string | string[] }) => c.name !== chatName && !c.behaviors?.includes(HIDDEN))
  }

  getSeriesByCode(code: string) {
    return this.pathCodeMap.get(code)
  }

  getSeriesWithPopulatedChats() {
    return Array.from(this.pathMap.values()).filter(p => p.hasPopulatedChats)
  }
  
  getSeriesWithBehavior(behavior: string) {
    return Array.from(this.pathMap.values()).filter(p => p.behaviors?.includes(behavior))
  }

  getSingularGlobal(type: string): string {
    return this.getGlobal(`${type}.singular`) || type
  }

  getPluralGlobal(type: string): string {
    return this.getGlobal(`${type}.plural`) || `${type}s`
  }

  getGlobalsStartingWith(prefix: string) {
    return Object.entries(this.globals)
      .filter(([k, v]) => k.startsWith(prefix))
      .map(([k, v]) => ({ key: k, value: v }))
  }

  // Return observable for a single chat from Firestore
  // Mid/longterm plan:
  // 1. SharedChatService will request the chat document when a chat is opened
  // 2. The chat document will contain the full ordered list of statements
  // 3. Should be able to load/unload chats as needed
  // 4. Verify these chats are immutable as they are used (identify sloppy code in chat service)
  // 5. Make sure that web chats work with this approach

  getChatNamed(name: string|null) {
    return this.firebase.doc$(`${this.contentRoot}/web/chats/${name}`)
      .pipe(
        // tap(chat => console.log("getChatNamed", name, chat)),
        shareReplay(1)
      )
  }

  async getFullChat(name: string|null) {
    const ref = `${this.contentRoot}/web/chats/${name}`
    return await this.firebase.getFirestoreDoc(ref)
  }

  getChatSeedCost(conv: any): number {
    if (conv.seedCost)
      return conv.seedCost
    else {
      const def = this.getGlobal(DEFAULT_CHAT_SEED_COST_KEY)
      return def ? parseInt(def) : 10
    }
  }

  getChallengeSeedCost(): number {
    return this.getGlobal(DEFAULT_CHALLENGE_SEED_COST_KEY) || 10
  }  

  getPromptGuides() {
    return this.firebase.collection$(`${this.contentRoot}/web/promptGuides`)
      .pipe(
        tap((guides:PromptGuide[]) => {
          this.promptGuides = guides
          // console.log("getPromptGuides", guides)
        }),
        shareReplay(1))
  }

  getValuesForDefaultAttributes(params: { userCreatedAt: string }) {
    return Object.values(this.defaultAttributeSpecMap)
      .map((attr: any) => {
        if (this.attributeHasBehavior(attr, "defaultToTodaysDate"))
          return { key: attr.id, value: nowstrISO() }
        else if (this.attributeHasBehavior(attr, "defaultToSixMonthsFromNow"))
          return { key: attr.id, value: addstrISO({ months: 6 }) }
        else if (this.attributeHasBehavior(attr, "defaultToTwelveWeeksFromNow"))
          return { key: attr.id, value: addstrISO({ weeks: 12 }) }
        else if (this.attributeHasBehavior(attr, "defaultToUserCreationDate"))
          return { key: attr.id, value: params.userCreatedAt }
        else if (attr.inputType === 'OPTIONS') {
          const opt = this.getDefaultOption(attr)
          return opt ? { key: attr.id, value: opt.name } : null
        }
        else return null
      })
      .filter(attr => !!attr)
  }
}
