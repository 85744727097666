<ion-app>
  <ion-split-pane
    when="sm"
    contentId="main-content"
    style="--side-max-width: 300px;"
  >
    <ion-menu type="overlay" contentId="main-content">
      <ion-header>
        <ion-toolbar color="light">
          <div class="flex flex-row justify-center">
            <img src="assets/menulogo.svg" style="padding: 3px 0 9px 10px">
            <div class="self-center text-lg font-semibold pl-3">admin</div>
          </div>        
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="p-0">
          <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <ion-item
              (click)="selectedIndex = i; processMenuClick(p)"
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="p.url ? [ p.url ] : ''"
              [class.selected]="selectedIndex === i"
            >
              <ion-icon *ngIf="p.icon"
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"/>
              <span slot="start" *ngIf="p.emoji" class="mr-8">{{ p.emoji }}</span>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div *ngIf="auth.user$ | async as user" class="p-2 text-left text-sm">
          <span>{{ user.email }} | </span>
          <a class="text-blue-400" [routerLink]="['login', { signout: true }]" routerDirection="root">Sign Out</a>
          <div><i>Build: </i>{{ environment.buildTag }}</div>
          <div><i>Release: </i>{{ environment.releaseTag }}</div>
          <div *ngIf="environment.patchLevel > 0"><i>Patch Level: </i>{{ environment.patchLevel }}</div>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
