// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppSource } from "@cheaseed/node-utils";

export const environment = {
  appSource: AppSource.Admin,
  production: false,
  useEmulators: false,
  linkHost: 'https://share-dev.cheaseed.com',
  firebase: {
    apiKey: 'AIzaSyCVSz4hXNSVo48Kn30aofjHunDn3eKeji4',
    authDomain: 'cheaseed-dev-bb89c.firebaseapp.com',
    databaseURL: 'https://cheaseed-dev-bb89c.firebaseio.com',
    projectId: 'cheaseed-dev-bb89c',
    storageBucket: 'cheaseed-dev-bb89c.appspot.com',
    messagingSenderId: '232970175996',
    appId: '1:232970175996:web:c1e032401f24a9812d9e79',
    measurementId: 'G-XNV5T125K0',
  },
  clevertap: {
    accountID: 'TEST-K5K-R79-7R6Z',
    passCode: 'CTM-IMV-IWUL',
    url: "https://api.clevertap.com/1/upload" 
  },
  azure: {
    endpoint: 'https://cheaseed-australia.openai.azure.com/', 
    key: '58587c75fdd54a6aa0de9b46e51d140b',
    models: [ 'gpt-4-1106-preview', 'gpt-35-turbo-16k', 'gpt-4' ]
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
