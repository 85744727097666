import { Inject, Injectable } from '@angular/core'
import { diff, diffpercent, formatDate, getTermsForType, MMDDYYYY_FORMAT, StatsKeys, subtractFrom } from '@cheaseed/node-utils'
import { ChatQueueService } from './chat-queue.service'
import { ContentService } from './content.service'
import { EntryService } from './entry.service'
import { LearnService } from './learn.service'
import { FirebaseService } from './firebase.service'
import { ProgramService } from './program.service'
import { SequenceAdvisorService } from './sequence-advisor.service'
import { SharedEventService } from './shared-event.service'
import { CLOUD_OPEN_AI, SharedUserService } from './shared-user.service'
import { StatsManagerService } from './stats-manager.service'
import { TaskSchedulerService } from './task-scheduler.service'
import { add } from 'date-fns'
import { PointsService } from './points.service'
import { CleverTapService } from './clevertap.service'
import { PromptService } from './prompt.service'
import { ReportService } from './report.service'
import { ChatStateService } from './chat-state.service'
import { GroupService } from './group.service'

/**
 * EvaluatorService is responsible for evaluating expressions specified in 1) **Statement** enableIf and onCompletion fields and 2) **AttributeSpec** captureIf fields.
 *   - An enableIf or captureIf is a javascript expression that returns a boolean (true/false)
 *   - An onCompletion is an expression that may call javascript functions that have side effects
 * 
 * The EvaluatorService provides methods that are referred to as **@-functions** for use in the expressions.
 *  - Some @-functions return a boolean for use in enableIf statements.
 *  - Some @-functions are procedures that have side effects elsewhere, for use in onCompletion statements.
 * 
 * See public methods below for @-function documentation.
 * 
 * In addition to supporting simple javascript expression syntax, the EvaluatorService:
 * 1. accepts $-variables, which lookup current user keys in the system.
 * 1. accepts $$ as the last response specified by the user
 * 1. accepts AND and OR logical connectors (as well as && and ||)
 * 1. correctly replaces UTF double quote characters
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class EvaluatorService {
  /** @internal */
  private currentFacts:any
  /** @internal */
  private currentQuestionSet: string|null = null
  /** @internal */
  private currentQuestionSetAnswered = false
  /** @internal */
  private entriesCreated: any = {}

  /** @internal */
  constructor(
    @Inject('UtilityService') private utilityService: any,
    private firebase: FirebaseService,
    private chatQueueService: ChatQueueService,
    private chatStateService: ChatStateService,
    private contentService: ContentService,
    private entryService: EntryService,
    private eventService: SharedEventService,
    private pointsService: PointsService,
    private statsMgrService: StatsManagerService,
    private sequenceAdvisor: SequenceAdvisorService,
    private taskScheduler: TaskSchedulerService,
    private userService: SharedUserService,
    private programService: ProgramService,
    private learnService: LearnService,
    private clevertapService: CleverTapService,
    private reportService: ReportService,
    private groupService: GroupService,
    private promptService: PromptService) 
  { 
    // console.log("EvaluatorService constructed")
  }

  /** @internal */
  reset() {
    console.log("EvaluatorService resetting conversation")
    this.currentQuestionSet = null
    this.currentQuestionSetAnswered = false
  }

  /** @internal */
  resetTrackedEntries() {
    this.entriesCreated = {}
  }

   /**
   * @returns Returns true if current user is authenticated
   * @category USER
   * @example
   *
   *    ```
   *     @isUserAuthenticated()
   *    ```
   */
   isUserAuthenticated() : boolean {
    return this.userService.isAnonymous()
  }

  /**
   * @returns Returns true if current user is member of prepaid group
   * @category USER
   * @example
   *
   *    ```
   *     @isUserPrepaidGroupMember()
   *    ```
   */
   isUserPrepaidGroupMember(): boolean {
    const group = this.groupService.currentUserGroup()
    return group?.pricingPlan === 'prepaid'
  }

  /**
     * @returns Returns true if current user is member of affiliate group
     * @category USER
     * @example
     *
     *    ```
     *     @isUserAffiliateGroupMember()
     *    ```
     */
  isUserAffiliateGroupMember(): boolean {
    const group = this.groupService.currentUserGroup()
    return group?.pricingPlan === 'affiliate'
  }

  /**
     * @returns Returns true if current user is member of a group
     * @category USER
     * @example
     *
     *    ```
     *     @isUserGroupMember()
     *    ```
     */
  isUserGroupMember(): boolean {
    return !!this.groupService.currentUserGroup()
  }

   /**
   * @param datestring - A YYYY-MM-DD string
   * @param numDays - A number of days
   * @returns Returns true if datestring is sooner than numDays days from now
   * @category DATE
   * @example
   *
   *    ```
   *     @isDateBeforeDaysFromNow($$, 7)
   *    ```
   */
  isDateBeforeDaysFromNow(datestring: string, numDays: number) : boolean {
    const test = add(new Date(), { days: numDays })
    test.setHours(0,0,0,0)
    return new Date(datestring) <= test
  }

   /**
   * @param datestring - A YYYY-MM-DD string
   * @param numDays - A number of days
   * @returns Returns true if datestring is greater than numDays days from now
   * @category DATE
   * @example
   *
   *    ```
   *     @isDateAfterDaysFromNow($$, 7)
   *    ```
   */
  isDateAfterDaysFromNow(datestring: string, numDays: number) : boolean {
    const test = add(new Date(), { days: numDays })
    test.setHours(0,0,0,0)
    return new Date(datestring) >= test
  }

   /**
   * @param datestring - A YYYY-MM-DD string
   * @param fromDays - A number of days
   * @param toDays - A number of days
   * @returns Returns true if datestring is between fromDays and toDays days from now
   * @category DATE
   * @example
   *
   *    ```
   *     @isDateWithinDaysFromNowRange($$, 7, 14)
   *    ```
   */
  isDateWithinDaysFromNowRange(datestring: string, fromDays: number, toDays: number) : boolean {
    return this.isDateBeforeDaysFromNow(datestring, toDays) && this.isDateAfterDaysFromNow(datestring, fromDays)
  }

  /**
   * @param startDate start date YYYY-MM-DD
   * @param endDate end date YYYY-MM-DD or null for today's date
   * @param unit one of day, week, month
   * @returns Returns number of days/weeks/months between endDate and startDate
   * @category DATE
   * @example
   *
   *    ```
   *     @timediff($ReviewConfig.reviewStartDate, $ReviewConfig.reviewEndDate, "day") < 10
   *     @timediff($ReviewConfig.reviewStartDate, null, "week") < 3
   *    ```
   */
  timediff(startDate:string, endDate:string, unit:string) : number {
    return diff(startDate, endDate, unit)
  }

/**
   * @param startDate start date YYYY-MM-DD
   * @param endDate end date YYYY-MM-DD
   * @returns Returns number of days/weeks/months between endDate and startDate
   * @category DATE
   * @example
   *
   *    ```
   *     @timediffpercent($ReviewConfig.reviewStartDate, $ReviewConfig.reviewEndDate) < 0.25
   *    ```
   */
  timediffpercent(startDate:string, endDate:string) : number {
    return diffpercent(startDate, endDate)
  }

  /**
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param opt - The name of one option
   * @category MULTIOPTIONS
   * @returns Returns true if opt is one of the options selected in obj
   *
   * @example
   *    ```
   *     @hasOptionSelected($$, 'level.low')
   *    ```
   */
  hasOptionSelected(obj: any, opt: string) : boolean {
    // console.log("hasOptionSelected", obj, opt)
    return obj && obj[opt]
  }

  /**
   * @param type - A type of entry (null for all)
   * @returns Returns true if the user has saved entries of this type
   *
   * @example
   *    ```
   *     @hasEntriesOfType('Accomplishment')
   *    ```
   */
  hasEntriesOfType(type: string) : boolean {
    return this.entryService.getEntriesOfType(type)?.length > 0
  }

  /**
   *
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param opt - The name of an option
   * @category MULTIOPTIONS
   * @returns Returns true if opt is the only option selected
   *
   * @example
   *
   *    ```
   *     @hasOnlyOneOptionSelected($$, 'test.a')
   *    ```
   */
  hasOnlyOneOptionSelected(obj: any, opt: string) {
    if (obj) {
        const hits = Object.keys(obj).filter(item => obj[item])
        return obj[opt] && hits.length === 1
    }
    else
      return false
  }

  /**
   *
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param counts - An array of integers representing counts
   * @category MULTIOPTIONS
   * @returns Returns true if the number of options selected is one of the counts in the array
   *
   * @example
   *
   *    ```
   *     @hasNOptionsSelected($$, [ 1, 2 ])
   *    ```
   */
  // @hasNOptionsSelected($$, [ 1, 2 ])
  hasNOptionsSelected(obj: any, counts: number[]) : boolean {
    if (obj) {
        const hits = Object.keys(obj).filter(item => obj[item])
        return counts.includes(hits.length)
    }
    else
      return false
  }

  /**
   * hasMoreThanNOptionsSelected
   *
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param n - An integer between 0 and the number of options available in obj
   * @category MULTIOPTIONS
   * @return Returns true if the number of options selected is greater than n
   *
   * @example
   *
   *    ```
   *     @hasMoreThanNOptionsSelected($$, 3)
   *    ```
   */
  hasMoreThanNOptionsSelected(obj: any, n: number) : boolean {
    if (obj) {
        const hits = Object.keys(obj).filter(item => obj[item])
        return hits.length > n
    }
    else
      return false
  }

  /**
   * 
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param opts - An array of option names
   * @category MULTIOPTIONS
   * @returns Returns true if any of the values in opts are selected in obj
   *
   * @example
   *
   *    ```
   *     @hasMoreThanNOptionsSelected($$, 3)
   *    ```
   */
  hasAnyOptionSelected(obj: string, opts: any[]) : boolean {
    if (obj) {
      for (const o of opts) {
        if (obj[o])
          return true
      }
    }
    return false
  }

  /**
   *
   * @param obj - A multioption attribute value object, in which each key is the name of the option and each value is true
   * @param opts - An array of option names
   * @category MULTIOPTIONS
   * @returns Returns true if all of the values in opts are selected
   *
   * @example
   *    ```
   *     @hasMultipleOptionsSelected($$, [ 'choice.1', 'choice.3', 'choice.5' ])
   *    ``` 
   */
  hasMultipleOptionsSelected(obj: any, opts: any[]) : boolean {
    // console.log("hasMultipleOptionsSelected", obj, opts)
    if (obj) {
      for (const o of opts)
        if (!obj[o])
          return false
      return true
    }
    else
      return false
  }

  /**
   *
   * @param type - An entry type
   * @returns Returns true if an entry was created within this chat session
   *
   * @example
   *    ```
   *     @wasEntryJustCreated("Accomplishment")
   *    ``` 
   */
  wasEntryJustCreated(type: string) : boolean {
    return this.entriesCreated[type] || Object.values(this.entriesCreated).find((e:any) => e.type === type)
  }

  /**
   * @returns Returns true if an addableEntry was saved for the given statementName
   *
   * @example
   *    ```
   *     @wasAddableEntrySaved('TestChats.addEntry')
   *    ``` 
   */
   wasAddableEntrySaved(statementName: string) : boolean {
    return this.entryService.hasAddableEntry(statementName)
  }

  /**
   *
   * @param name - A name of a conversation
   * @returns Returns true if the conversation has already been completed by the user
   *
   * @example
   *
   *    ```
   *     @hasConversationCompleted('TestChat')
   *    ```
   */
  hasConversationCompleted(name: string) : boolean {
    return this.chatStateService.isChatCompleted(name)
  }
  
  /**
   * @param name - A name of a conversation
   * @param numDays - number of days
   * @returns Returns true if the conversation was completed by the user in the last numDays
   *
   * @example
   *    ```
   *    @hasConversationCompletedWithinLastDays('TestChat', 5)
   *    ```
   */
  hasConversationCompletedWithinLastDays(name: string, numDays: number) : boolean {
    const time = this.chatQueueService.getConversationStatus(name)
    if (!time) return false
    const past = add(new Date(), { days: 0 - numDays })
    return new Date(time) >= past
  }

  /**
   * get the current points streak length
   * 
   * @returns the length of the current streak
   *
   * @example
   *
   *    ```
   *    @getCurrentStreak()
   *    ```
   */
   getCurrentStreak(): number {
    const streak:any = this.pointsService.getCurrentStreak()
    return streak.streak
  }

  /**
   * get the state of the learn sequence (new, inprogress, completed)
   * 
   * @returns the state of the initial learn sequence
   *
   * @example
   *
   *    ```
   *     @getLearnState()
   *    ```
   */
     getLearnState(): string {
      return this.learnService.getLearnStateDefault()
    }

  /**
   * Return the number of entries of a specific type created in the last n units of time
   * 
   * @param type the entry type (null for all)
   * @param unit the unit of time ("days", "weeks", "months")
   * @param n the number of time units 
   * @returns the number of entries created since the time specified
   *
   * @example
   *
   *    ```
   *     @numEntriesCreatedSince("Accomplishment", "weeks", "12")
   *    ```
   */
   numEntriesCreatedSince(type: string, unit: string, n: number): number {
    return this.entryService.numEntriesCreatedSince(type, unit, n)
  }

  /**
   * Return the number of entries of a specific type based on the value of attributeName being greater than sinceDate
   * 
   * @param type the entry type
   * @param attrName the name of the attribute to use as the date
   * @param sinceDate the date to compare against
   * @returns the number of entries with attributeName since the date specified
   *
   * @example
   *
   *    ```
   *     @numEntriesWithAttributeDateSince("Accomplishment", "dateOccurred", "2023-08-01") > 10
   *    ```
   */
  numEntriesWithAttributeDateSince(type: string, attrName: string, sinceDate: string): number {
    return this.entryService.listEntriesWithAttributeDateSince(type, attrName, sinceDate).length
  }

  /**
   * Return the number of entries of specified types
   * 
   * @param type the comma-separated list of entry types (use "null" for all)
   * @returns the sum of the number of entries of each type
   *
   * @example
   *
   *    ```
   *    @numEntriesOfType("Accomplishment")
   *    @numEntriesOfType("Accomplishment,Accolade")
   *    ```
   */
  numEntriesOfType(type: string) : number {
    if (type === 'null')
      return this.entryService.getEntriesOfType(null)?.length || 0
    else {
      const types = type.split(',')
      let sum = 0
      for (const t of types)
        sum += this.entryService.getEntriesOfType(t.trim())?.length || 0
      return sum
    }
  }

  /**
   * Return the last computed review ready status
   * 
   *   0 - if # accomplishments remaining or # weeks remaining is zero (Done)
   *   1 - if # accomplishments remaining are more than 20% behind # weeks remaining (Behind)
   *   2 - if # accomplishments remaining are within 20% of # weeks remaining (OnPace)
   *   3 - if # accomplishments remaining are more than 20% ahead of # weeks remaining (Ahead)
   * 
   * @returns a value 0-3
   *
   * @example
   *
   *    ```
   *     @getReviewReadyStatus == "2"
   *    ```
   */
   getReviewReadyStatus() {
    return this.userService.getUserKey(StatsKeys.ReviewReadyStatusKey)
  }

  /** @internal */
  debug(s: string) {
    // if (true) 
      console.log(s)
  }

  /** @internal */
  evalCountResponseExpr(cnt: number, expr: any) {
    // Return true if cnt satisfies expression
    // Expr can be one of:
    // n, =n : cnt === expr
    // >n : cnt > expr
    // <n : cnt < expr
    // console.log("evalCountResponseExpr", cnt, expr)
    const val = `${cnt || 0}`
    if (typeof expr === 'string') {
      this.debug("evalCountResponseExpr: expr is string")
      if (expr.startsWith('>='))
        return val >= expr.substring(2)
      else if (expr.startsWith('<='))
        return val <= expr.substring(2)
      else if (expr.startsWith('>'))
        return val > expr.substring(1)
      else if (expr.startsWith('<'))
        return val < expr.substring(1)
      else 
        return val == expr
    }
    else
      return val == expr
  }

  /**
  * Use when you have N questions, each with the same M response OPTIONS, and you want to test for different combinations of responses across all questions
  *
  * @param id Name of question set
  * @param questions Array of question names 
  * @param expr A JSON representing a combination of counts of each question response, or null. Use null as a catchall/else expression.
  * @category OPTIONS
  * @return Returns true if expr evaluates to true
  *
  * @example
  * ```
  * @countResponses ( 'feelings', [$Pitching.positivity, $Pitching.confidence, $Pitching.clarity], { or: [ { high: 3 }, {skip: 3} ] } )
  * @countResponses ( 'feelings', [$Pitching.positivity, $Pitching.confidence, $Pitching.clarity], { and: [ { low: 0}, { or: [ { high: 1 }, { high: 2} ] } ] } )
  * @countResponses ( 'feelings', [$Pitching.positivity, $Pitching.confidence, $Pitching.clarity], null ) // else
  * @countResponses ( 'feelings', [$Pitching.positivity, $Pitching.confidence, $Pitching.clarity], { or: [ { and: [ { high: 2 }, {med: 1} ] }, { high: 3 } ] } )
  * @countResponses ( 'feelings', [$Pitching.positivity, $Pitching.confidence, $Pitching.clarity], null ) // else
  * ```
  */
  countResponses( id: string, questions: string[], expr: any ) : boolean {
    // If id has changed or is null
    if (!id || this.currentQuestionSet !== id) {
      this.currentQuestionSet = id
      this.currentQuestionSetAnswered = false
    }
    // Handle null question set first, reset the marker
    if (!expr) {
      if (this.currentQuestionSet === id && !this.currentQuestionSetAnswered) {
        this.debug("countResponses detected null expression and no prior response, resetting currentQuestionSet, returning true")
        this.currentQuestionSetAnswered = false
        this.currentQuestionSet = null
        return true
      }
      else {
        this.debug("countResponses detected null expression but prior response, resetting currentQuestionSet, returning false")
        this.currentQuestionSetAnswered = false
        this.currentQuestionSet = null
        return false
      }
    }
    else if (this.currentQuestionSet === id && this.currentQuestionSetAnswered) {
      this.debug(`countResponses detected prior response, return false`)
      return false
    }
    else if (this.currentQuestionSet === id && !this.currentQuestionSetAnswered) {
      this.debug("countResponses detected no prior response, evaluating")
      const cnts:any = {}
      // Count number of responses of each value
      for (const q of questions)
        cnts[q] = (cnts[q] || 0) + 1
        this.debug(`countResponses ${id} ${JSON.stringify(cnts)} ${JSON.stringify(expr)}`)
      // Parse expr
      if (expr["or"]) {    
        for (const c of expr["or"]) {
          if (c["and"]) {
            let subresult = true
            for (const d of c["and"]) {
              const [key, val] = Object.entries(d)[0]
              this.debug(`Testing ${JSON.stringify(d)} whether count is not ${val}`)
              if (!this.evalCountResponseExpr(cnts[key], val))
                subresult = false
            }
            if (subresult)
              return (this.currentQuestionSetAnswered = true)
          }
          else {
            const [key, val] = Object.entries(c)[0]
            this.debug(`Testing ${JSON.stringify(c)} whether count is ${val}`)
            if (this.evalCountResponseExpr(cnts[key], val))
              return (this.currentQuestionSetAnswered = true)
          }
        }
        return false
      }
      else if (expr["and"]) {
        for (const c of expr["and"]) {
          if (c["or"]) {
            let subresult = false
            for (const d of c["or"]) {
              const [key, val] = Object.entries(d)[0]
              this.debug(`Testing ${JSON.stringify(d)} whether count is ${val}`)
              if (this.evalCountResponseExpr(cnts[key], val))
                subresult = true
            }
            if (!subresult)
              return false
          }
          else {
            const [key, val] = Object.entries(c)[0]
            this.debug(`Testing ${JSON.stringify(c)} whether ${key} count is not ${val}`)
            if (!this.evalCountResponseExpr(cnts[key], val))
              return false
          }
        }
        return (this.currentQuestionSetAnswered = true)
      }
      else {  // a single expression, e.g. {"level.high": ">1"}
        const [key, val] = Object.entries(expr)[0]
        if (this.evalCountResponseExpr(cnts[key], val))
          return (this.currentQuestionSetAnswered = true)
        else
          return false
      }
    }
    return false
  }

  /**
   * Sets "user.targetNumAccomplishments" to the number of weeks between the startDate (ReviewConfig.reviewStartDate) and endDate (ReviewConfig.reviewEndDate)
   * Use only in Statement OnCompletion
   * 
   * @category OnCompletion
   * @example
   *    ```
   *     @setTargetAccomplishments()
   *    ```
   */
  setTargetAccomplishments() {
    this.statsMgrService.setTargetAccomplishments()
  }

  /**
   * Sets "user.targetNumLeads" to the number of weeks times a factor based on the Networking target level ("NetworkStatsConfig.targetNetwork")
   * 
   *   "level.low": 0.25
   *   "levelMedDefault": 1
   *   "level.high": 3
   * 
   * Use only in Statement OnCompletion
   * 
   * @category OnCompletion
   * @example
   *    ```
   *     @setTargetNumNetworkLeads()
   *    ```
   */
   setTargetNumNetworkLeads() {
    this.statsMgrService.setTargetNetworkLeads()
   }

  /**
   * Sets "user.targetNumPitches" to the number of weeks times a factor based on the Pitch target level ("PitchStatsConfig.targetPitches")
   * 
   *   "level.low": 0.5
   *   "levelMedDefault": 1
   *   "level.high": 2
   * 
   * Use only in Statement OnCompletion
   * 
   * @category OnCompletion
   * @example
   *    ```
   *     @setTargetNumPitches()
   *    ```
   */
   setTargetNumPitches() {
      this.statsMgrService.setTargetPitches()
   }

  /**
   * Sets "user.targetNumAsks" to the number of months times a factor based on the Ask target level ("AskStatsConfig.targetAsks")
   * 
   *   "level.low": 0.33
   *   "levelMedDefault": 1
   *   "level.high": 2
   * 
   * Use only in Statement OnCompletion
   * 
   * @category OnCompletion
   * @example
   *    ```
   *     @setTargetNumPitches()
   *    ```
   */
    setTargetNumAsks() {
      this.statsMgrService.setTargetAsks()
    }

  /**
   * Sets key to the provided value
   * Use only in Statement OnCompletion
   * 
   * @param key Name of user key to set
   * @param value Value to set
   * @category OnCompletion
   * @example
   *    See **ReviewSettings.endDate**:
   *    ```
   *     @setAttribute("Onboarding.mostImportHigh", "highestSelfAdvocate")
   *    ```
   */
  setAttribute(key: string, value: string) {
    console.log("@setAttribute", key, value)
    this.userService.setUserKey(key, value)
    return true
  }

  /**
   * Reset multiple keys to null
   * Use only in Statement OnCompletion
   * 
   * @param keys Array of key names to reset
   * @category OnCompletion
   * @example
   *    ```
   *     @resetAttributesToNull([ "CoachReview.chronJob", "CoachReview.currentJob" ])
   *    ```
   */
  resetAttributesToNull(keys: string[]) {
    console.log("@resetAttributesToNull", keys)
    for (const key of keys)
      this.userService.setUserKey(key, null)
    return true
  }

  /**
   * Sets keys to the provided value
   * Use only in Statement OnCompletion
   * 
   * @param keys Comma delimited key names to set
   * @param value Value to set
   * @category OnCompletion
   * @example
   *    ```
   *    @resetAttributes("CoachReview.finalAddition,CoachReview.otherNeedToKnow,CoachReview.clarificationQuestion,CoachReview.wantsToKnow,CoachReview.responseToDraft,CoachReview.nowLater", null)
   *    ```
   */
  resetAttributes(keys: string, value: string) {
    console.log("@resetAttributes", keys)
    for (const key of keys.split(',')) {
      this.setAttribute(key.trim(), value)
    }
  }

  /**
   * Sets key to the first option of the AttributeSpec for which enableIf evaluates to true
   * Use only in Statement OnCompletion
   * 
   * @param key Name of user key to set
   * @category OnCompletion
   * @example
   *    See **ReviewSettings.endDate**:
   *    ```
   *     @setAttributeToFirstEnabledOption("Onboarding.mostImportHigh")
   *    ```
   */
  setAttributeToFirstEnabledOption(key: string) {
    console.log("@setAttributeToFirstEnabledOption", key)
    const attribute = this.contentService.getAttributeSpec(key)
    if (attribute && attribute.optionLinks) {
      const enabledOptions = attribute.optionLinks.filter((opt:any) => {
        try {
          return opt.enableIf ? this.evaluate(opt.enableIf, this.currentFacts) : true
        }
        catch(err) {
          console.error(err)
        }
      })
      if (enabledOptions.length > 0) {
        const value = enabledOptions[0].name
        console.log("@setAttributeToFirstEnabledOption", key, value)
        this.userService.setUserKey(key, value)
      }
    }
  }

  /**
   * Get the option description of the attribute's current value
   * Use only in Statement OnCompletion
   * 
   * @param key Name of attribute
   * @category OnCompletion
   * @example
   *    ```
   *     @getOptionDescription("First100Days.think30Seconds")
   *    ```
   */
  getOptionDescription(key: string): string|null {
    const attribute = this.contentService.getAttributeSpec(key)
    let val = this.currentFacts[key]
    if (attribute.inputType === 'COMBOBOX' && val === 'Other') {
      val = this.currentFacts[this.userService.getOtherAttributeName(key)]
    }
    if (attribute && attribute.optionLinks) {
      const option = attribute.optionLinks.find((opt:any) => opt.name === val)
      if (option)
        return option.description
    }
    return val
  }

  /**
   * Execute the SequenceAdvisor, used when building a playlist from user preferences
   * Use only in Statement OnCompletion
   * 
   * @param goal The goal the user specified
   * @category OnCompletion
   * @example
   * 
   *    See **"BuildYourPlaylist.tapForPlaylist"**:
   * 
   *    ```
   *     @runSequenceAdvisor($Onboarding.mostImportantHigh)
   *    ```
   */
  runSequenceAdvisor(goal: string) {
    console.log("Running SequenceAdvisor for", goal)
    this.sequenceAdvisor.invoke(goal, this.currentFacts)
  }

  /**
   * Execute the TaskScheduler, used when setting reminders
   * Use only in Statement OnCompletion
   * 
   * @param taskSetName the name of the TaskSet
   * @category OnCompletion
   * @example
   * 
   *    See **"SatisfactionSettings.reminderFrequency"**:
   * 
   *    ```
   *     @scheduleTasks("TinyHabitReminder")
   *    ```
   */
  scheduleTasks(taskSetName: string) {
    if (this.userService.isAnonymous()) return
    console.log("Scheduling TaskSet", taskSetName)
    this.taskScheduler.schedule(taskSetName)
  }

  /**
   * Execute the TaskScheduler, used when setting reminders
   * Use only in Statement OnCompletion
   * 
   * @param type the type of the TaskSets
   * @category OnCompletion
   * @example
   * 
   *    See **"SatisfactionSettings.reminderFrequency"**:
   * 
   *    ```
   *     @runTaskSchedulerByType("SatisfactionReminder")
   *    ```
   */
  scheduleTasksByType(type: string) {
    if (this.userService.isAnonymous()) return
    console.log("Scheduling TaskSets for type", type)
    this.taskScheduler.scheduleByType(type, this.currentFacts)
  }

  /**
   * Execute the TaskScheduler, used when setting reminders
   * Use only in Statement OnCompletion
   * 
   * @param taskSetName the taskSetName
   * @param data the data needed for scheduling: start_work, end_work, dayString, freq
   * @category OnCompletion
   * @example
   * 
   *    See **"SatisfactionSettings.reminderFrequency"**:
   * 
   *    ```
   *     @scheduleSatisfactionRemindersFromData('SatisfactionReminders', { freq: 'level.low', days: '0123456', start_work: '0900', end_work: '1700'})
   *    ```
   */
  // 
  scheduleSatisfactionRemindersFromData(taskSetName:string, data: any) {
    if (this.userService.isAnonymous()) return
    console.log(`Scheduling TaskSets for type ${taskSetName} from data ${data}`)
    this.taskScheduler.scheduleSatisfactionRemindersFromData(taskSetName, data)
  }

  /**
   * Cancel reminders by type
   * Use only in Statement OnCompletion
   * 
   * @param taskSetName the name of the TaskSet
   * @category OnCompletion
   * @example
   * 
   *    See **"TinyHabitReminders.turnOffReminders"**:
   * 
   *    ```
   *     @cancel("TinyHabitReminder")
   *    ```
   */
  cancelTasks(taskSetName: string) {
    if (this.userService.isAnonymous()) return
    this.taskScheduler.cancel(taskSetName)
  }

  /**
   * Cancel reminders by type
   * Use only in Statement OnCompletion
   * 
   * @param type the type of the TaskSets
   * @category OnCompletion
   * @example
   * 
   *    See **"SatisfactionSettings.turnOffReminders"**:
   * 
   *    ```
   *     @cancelTasksByType("SatisfactionReminder")
   *    ```
   */
  cancelTasksByType(type: string) {
    if (this.userService.isAnonymous()) return
    this.taskScheduler.cancelByType(type)
  }

  /**
   * Goto a specific statement in the current chat. Use the unqualified statementName of the Statement.
   * Use only in Statement OnCompletion
   * 
   * @param statementName the name of the TaskSet
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @goto("turnOffReminders")
   *    ```
   */
  goto(statementName: string) {
    // Return a goto object
    return { goto: statementName }
  }

  /**
   * Create or update a new entry based on attributes collected in this chat or elsewhere.
   * If the statement is repeatedly completed within the chat session, the corresponding entry will be updated.
   * If there are multiple entries to create of the same type in the chat, provide an entryId; otherwise, don't
   * 
   * @param type the type of entry
   * @param attributes a dictionary of attribute names and values
   * @param entryId the id of the entry, used when there are multiple entries of the same type created in a chat (optional)
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @createNewEntry("Motivation", { name: "My first motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" })
   *  or
   *     @createNewEntry("Motivation", { name: "My first tangible motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" }, 'tangible')
   *     @createNewEntry("Motivation", { name: "My first emotional motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" }, 'emotional')
   *    ```
   */
  createNewEntry(type:string, attributes:any, entryId:string|null = null) {
    console.log('In createNewEntry evaluator service')
    if (!attributes.name)
      attributes.name = "Created from chat"
    Object.keys(attributes).forEach(key => {
      const val = attributes[key] 
      if (typeof val !== 'string') {
        attributes[key] = JSON.stringify(val)
      }
    })
    const entry = this.entriesCreated[entryId || type]
    if (entry) {
      entry.attributes = attributes
      this.entryService.updateEntryLite(entry)
        .then(result => this.entrySuccess(result, type, entryId as string, true))
    }
    else {
      this.entryService.createEntryLite(type, null, attributes)
        .then(result => this.entrySuccess(result, type, entryId as string, false))
    }
  }

  /**
   * Create or update a new entry based on attributes collected in this chat or elsewhere, but only
   * do so if the attribute on this statement was entered (not skipped).
   * If the statement is repeatedly completed within the chat session, the corresponding entry will be updated.
   * If there are multiple entries to create of the same type in the chat, provide an entryId; otherwise, don't
   * 
   * @param type the type of entry
   * @param attributes a dictionary of attribute names and values
   * @param entryId the id of the entry, used when there are multiple entries of the same type created in a chat (optional)
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @createNewEntryIfAttributeEntered("Motivation", { name: "My first motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" })
   *  or
   *     @createNewEntryIfAttributeEntered("Motivation", { name: "My first tangible motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" }, 'tangible')
   *     @createNewEntryIfAttributeEntered("Motivation", { name: "My first emotional motivation", description: $CareerPlan.KnowTheOne, type: "motivation.money" }, 'emotional')
   *    ```
   */
  createNewEntryIfAttributeEntered(type:string, attributes:any, entryId:string|null = null) {
     if (this.currentFacts['lastResponse'])
       this.createNewEntry(type, attributes, entryId)
  }

  /**
   * Create or update an Objective attached to a goal named goalName.
   * If the statement is repeatedly completed within the chat session, the corresponding entry will be updated.
   * 
   * @param goalName the name of the Goal to attach the Objective to
   * @param objId the id of the Objective, used when there are multiple Objectives created in the chat
   * @param attributes a dictionary of attribute names and values
   * @category OnCompletion
   * @example
   * 
   *    ```
   * @attachObjectiveToGoal("Maximize My Internship", 'obj1', { name: "Get an offer for full time" });
   * @attachObjectiveToGoal("Maximize My Internship", 'obj2', { name: "Boost my resume" });
   * @attachObjectiveToGoal("Maximize My Internship", 'obj3', { name: "Make meaningful connections" });
   * @attachObjectiveToGoal("Maximize My Internship", 'obj4', { name: "Learn three new skills" });
   * @attachObjectiveToGoal("Maximize My Internship", 'obj5', { name: "Own part of a project that I can describe" });
   * @attachObjectiveToGoal("Maximize My Internship", 'obj6', { name: "Learn how the company works" });
   *    ```
   */
  attachObjectiveToGoal(goalName: string, objId: string, attributes: any) {
    // Find goal entry id
    const goals = this.entryService.getEntriesOfType('Goal')
    const entry = goals.length > 0 ? goals.find(item => item.displayName === goalName) : null
    if (entry) {
      const goalId = entry.docId
      this.createNewEntry('Objective', { goal: goalId, ...attributes }, objId)
      console.log("attachObjectiveToGoal attached Objective to Goal", goalId)
    }
    else
    console.log("attachObjectiveToGoal Error: Unable to find Goal", goalName)
  }  

/**
   * Set a user key to the provided value for later use in a COMBOBOX on an entry form.
   * This function will 1) prepend the value to the <key>.combo userkey and 2) assign the value to the <key>.last userkey.
   * 
   * @param key the attributeName used in an entry form
   * @param value the value to assign to <key>.last and <key>.combo
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @setUserKeyCombo('companyName', $$)
   *     @setUserKeyCombo('jobTitle', $$)
   *    ```
   */
  setUserKeyCombo(key:string, value: any) {
    console.log("@setUserKeyCombo", key, value)
    this.userService.setUserKeyLastUsed(key, value)
    this.userService.appendUserKeyList(key, value)
  }

/**
   * Delete a user key
   * Can be used for keys that are generated by prompts.
   * 
   * @param keys an array of key names
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @deleteUserKey([ 'user.generated.example', 'key2', 'key3' ])
   *    ```
   */
deleteUserKeys(keys:string[]) {
  console.log("@deleteUserKeys", keys)
  for (const k of keys)
    this.userService.deleteUserKey(k)
}

/**
   * Create or update a new entry based on attributes collected in this chat or elsewhere.
   * If the statement is repeatedly completed within the chat session, the corresponding entry will be updated.
   * If there are multiple entries to create of the same type in the chat, provide an entryId; otherwise, don't
   * 
   * @param progname the name of the program to start
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @startProgram("NegotiateJobOffer")
   *    ```
   */
  startProgram(progname:string) {
    if (progname)
      this.programService.startProgramNamed(progname)
  }

  /**
   *
   * @param name - A name of a program
   * @returns Returns true if the current program is named name
   *
   * @example
   *
   *    ```
   *     @isCurrentProgram('DipSlumpProgram')
   *    ```
   */
  isCurrentProgram(name: string) : boolean {
    return this.programService.getCurrentProgramName() === name
  }

  /**
   *
   * @returns Returns date program created as string
   *
   * @example
   *
   *    ```
   *     @currentProgramCreateDate()
   *    ```
   */
   currentProgramCreateDate() : string {
    return formatDate(this.programService.getCurrentProgramCreatedAt())
  }

  /**
   *
   * @param name The name of the series
   * @category OnCompletion
   *
   * @example
   *
   *    ```
   *     @addSeriesToMyList('Mindset of Negotiation')
   *    ```
   */
  addSeriesToMyList(name: string) {
    const series = this.contentService.pathMap.get(name)
    this.chatQueueService.addSeriesToPlaylist(name)
    this.presentToast(`Added ${series.title} to ${ getTermsForType("Playlist") }`)
  }

  /**
   *
   * @param name The name of the persona
   * @returns boolean True if the user has the named persona
   * @category USER
   * @example
   *
   *    ```
   *     @hasPersona('Female')
   *    ```
   */
  hasPersona(name: string) {
    return this.userService.userHasPersona(name)
  }

  assignPersonas() {
    // Start with current personas and either add or remove
    let assigned = this.userService.getPersonas()
    for (const p of this.contentService.personas) {
      if (this.evaluate(p.assignIf, this.userService.getCache())) {
        if (!assigned.includes(p.name)) {
          assigned = assigned.concat(p.name)
          this.userService.setPersonas(assigned)
          console.log("assignPersonas", assigned)
        }
      }
      else {
        if (assigned.includes(p.name)) {
          assigned = assigned.filter((a:any) => a !== p.name)
          this.userService.setPersonas(assigned)
          console.log("assignPersonas", assigned)
        }
      }
    }
  }


  /**
   *
   * @param names An array of chat names
   * @category OnCompletion
   *
   * @example
   *
   *    ```
   *     @addChatsToMyList([ 'IntroduceYourself', 'JobOfferMarket', 'JobOfferPushBack' ])
   *    ```
   */
  addChatsToMyList(names: string[]) {
    this.chatQueueService.addMultipleToPlaylist(names)
    // this.presentToast(`Added chats to ${ getTermsForType("Playlist") }`)
  }

  /**
   * Save user feedback and send notify feedback@cheaseed.com
   * 
   * @param type the EntrySpec name for the feedback attributes
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @saveFeedback("UserFeedback")
   *    ```
   */
  saveFeedback(type: string) {
    this.userService.saveFeedback(type)
  }  

  /**
   * Generate a read-only report entry of the given type
   * 
   * @param type the EntrySpec name, must have behavior ReadOnlyReportTemplate
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @generateReportEntry("TestReport")
   *    ```
   */
  generateReportEntry(type: string) {
    this.reportService.generateReadOnlyEntry(type)
  }  

  /**
   * Generate an approvable report entry of the given type
   * 
   * @param type the EntrySpec name, must have behavior ReadOnlyReportTemplate
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @generateReportEntryForApproval("TestReport")
   *    ```
   */
  generateReportEntryForApproval(type: string) {
    this.reportService.generateReadOnlyEntry(type, true)
  }  

  /**
   * Generate report entry of the given type that also needs review
   * 
   * @param type the EntrySpec name, must have behavior ReadOnlyReportTemplate
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @generateReportEntryForReview("TestReport")
   *    ```
   */
    generateReportEntryForReview(type: string) {
      this.reportService.generateReadOnlyEntry(type, false, true)
    }  
  
  /**
   * Request account deletion and notify feedback@cheaseed.com
   * 
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @requestAccountDeletion()
   *    ```
   */
   requestAccountDeletion() {
    this.userService.requestAccountDeletion()
  }  

/**
   * Create Clevertap user with given attributes as user properties and then send EmailAcquired event for the user
   * 
   * @param email the email address of the user
   * @param attributes the attributes to set as user properties
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @acquireClevertapEmail("test@cheaseed.com", { "feelingStuck": "yes", "perfFocus": "yes" })
   *    ```
   */
  acquireClevertapEmail(email: string, attributes: any) {
    this.clevertapService.createUserAndSendEvent(email, attributes, "EmailAcquired")
  }  
  
  /**
   * Schedule MaxInternship program triggers
   * TODO: Delete this when replaced in content
   * 
   * @param startDate the start date of the internship (MM/DD/YYYY)
   * @param endDate the end date of the internship (MM/DD/YYYY)
   * @param messageTime the time to schedule the trigger (HHMI)
   * @param numEvents the number of events to schedule
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @scheduleMaxInternshipTriggers('05/01/2022', '07/31/2022', '1430', 15)
   *    ```
   */
  scheduleMaxInternshipTriggers(
    startDate: string,
    endDate: string,
    messageTime: string,
    numEvents: number    
  ) {
      this.scheduleProgramTriggers('MaxInternship', startDate, endDate, messageTime, numEvents)
  }

  /**
   * Schedule program triggers
   * 
   * @param programName the name of the program
   * @param startDate the start date of the internship (MM/DD/YYYY)
   * @param endDate the end date of the internship (MM/DD/YYYY)
   * @param messageTime the time to schedule the trigger (HHMI)
   * @param numEvents the number of events to schedule
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @scheduleProgramTriggers('MaxInternship', '05/01/2022', '07/31/2022', '1430', 15)
   *    ```
   */
  scheduleProgramTriggers(
    programName: string,
    startDate: string,
    endDate: string,
    messageTime: string,
    numEvents: number    
  ) {
      if (this.userService.isAnonymous()) return
          
      const newEndDate = subtractFrom(endDate, { days: 7 })
      const data = {
        userId: this.userService.getCurrentUserId(),
        programName: programName,
        startDate: formatDate(startDate, MMDDYYYY_FORMAT),
        endDate: formatDate(newEndDate, MMDDYYYY_FORMAT),
        time: messageTime,
        numberOfEvents: numEvents,
        dnd: 'Saturday-Sunday',
        useSpread: true
      }
      this.firebase.callCloudFunction("scheduleProgramTriggers", data)
        .subscribe(() => {
          console.log("scheduled ProgramTriggers", data)
        })
  }

  /**
   * Cancel program event triggers
   * 
   * @param programName the name of the program to cancel
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @cancelProgramEventTriggers('MaxInternship')
   *    ```
   */
  cancelProgramEventTriggers(programName: string) {

      if (this.userService.isAnonymous()) return

      const data = {
        userId: this.userService.getCurrentUserId(),
        programName: programName 
      }
      this.firebase.callCloudFunction("cancelProgramTrigger", data)
        .subscribe(() => {
          console.log("cancelled ProgramTriggers", data)
      })
  }
  
  /**
   * Test program event trigger
   * 
   * @param programName the name of the program to trigger
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @testProgramEventTrigger('MaxInternship')
   *    ```
   */
  testProgramEventTrigger(programName: string) {
    this.eventService.recordCloudProgramEventTrigger({ programName })
  }

 /**
   * Set PromptSpec template and temperatures for testing
   * 
   * @param promptName the name of the prompt spec
   * @param promptTemplate the prompt template text
   * @param testTemperatures the test temperatures
   * @category OnCompletion
   * @example
   * 
   *    ```
   *     @setPromptSpec('test.prompt', "$TestAttributes.testTemplate", "$TestAttributes.testTemperatures")
   *    ```
   */
 setPromptSpec(promptName: string, promptTemplate: string, testTemperatures: string) {
    const isCloud = this.userService.getUserKey(CLOUD_OPEN_AI)
    if (!isCloud) {
      console.log("setPromptSpec", promptName, promptTemplate, testTemperatures)
      this.promptService.setSpec(promptName, { promptTemplate, testTemperatures })
    }
    else
      throw new Error("setPromptSpec not allowed in cloud prompting mode")
  }


  /** @internal */
  entrySuccess(result:any, type:string, entryId: string, updated = false) {
    const copy = Object.assign({}, result)
    this.entriesCreated[entryId || type] = copy
    console.log("entrySuccess", this.entriesCreated)
    this.presentToast(this.contentService.getGlobal(updated ? "updateEntryInChat.message" : "createEntryInChat.message") 
      || `Entry ${updated ? 'updated' : 'created'}`)          
  }

  /** @internal */
  presentToast(msg: string) {
    this.utilityService.presentToast(`<b>${msg}</b>`)
  }

  /** @internal */
  evaluate(expr: string, context: any, procedural = false): any {
      // Return true if expr evaluates to true using context
      // Replace $$ with last response
      // Handle shorthand expression tests, e.g. $content1.response === 'Yes'
      // Use $ prefix to reference objects in context
      // All context.user objects are flattened (i.e. "a.b.c")
      // let replaced = expr1.replace(/\$user.([^= \)]+)/g, 'facts.user["$1"]').replace(/\$/g, 'facts.')
      // console.log("evaluate", expr, context)
      const replaced = expr.replace(/\$\$/g, 'facts.lastResponse')
                          .replace(/@/g, 'facts.util.')
                          .replace(/\$([^= \>\<\,\]\))]+)/g, 'facts["$1"]')
                          .replace(/\sOR\s/gi, ' || ')
                          .replace(/\sAND\s/gi, ' && ')
                          .replace(/[\u2018\u2019]/g, "'")
                          .replace(/[\u201C\u201D]/g, '"')      // replace UTF-8 curly quotes
      context["util"] = this
      this.currentFacts = context
      // console.log(`Evaluator evaluating ${replaced}`, context)
      if (!replaced.includes(';')) // if a procedural call does not include multiple statements, treat as a single function call
        procedural  = false
      const func = Function('facts', procedural ? `${replaced}` : `return ${replaced}` )
      const result = func(context)
      // console.log('Evaluator evaluated', expr, result)
      return result
    }
}