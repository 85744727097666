import { Component, Inject, inject } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService, ContentService, UtilityService } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private router = inject(Router)
  private utilityService = inject(UtilityService)
  private contentService = inject(ContentService)
  public auth = inject(AuthService)


  public selectedIndex = 0
  public appPages:any[] = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'person',
    },
    {
      title: 'Groups',
      url: '/groups',
      icon: 'people',
    },
    {
      title: 'Offers',
      url: '/offers',
      icon: 'cash',
    },
    {
      title: 'OpenAI',
      url: '/open-ai',
      emoji: '🧠',
    },
    {
      title: 'Approvals',
      url: '/approvals',
      emoji: '👍',
    },
    {
      title: 'Reviews',
      url: '/reviews',
      emoji: '🧐',
    },
    {
      title: 'Prompt Status',
      url: '/prompting',
      emoji: '🚀'
    },
    {
      title: 'User Ledgers',
      url: '/ledgers',
      emoji: '💵'
    },
    {
      title: 'Cloned Users',
      url: '/users/cloned',
      icon: 'person',
    },  
    {
      title: 'Test Runs',
      url: '/test-runs',
      emoji: '🧪',
    },  
    {
      title: 'More',
      click: () => this.clickMore(),
      icon: 'ellipsis-horizontal',
    },
  ];

  morePages:any[] = [
    {
      title: 'Notifications',
      url: '/notifications',
      icon: 'paper-plane',
    },
    {
      title: 'TaskSets',
      url: '/content/taskset',
      icon: 'file-tray-stacked',
    },
    {
      title: 'Challenges',
      url: '/challenges',
      icon: 'paper-plane',
    },
    {
      title: 'Programs',
      url: '/programs',
      icon: 'paper-plane',
    },
    {
      title: 'Statistics',
      url: '/statistics',
      icon: 'stats-chart',
    },
    {
      title: 'Cloud Tasks',
      url: '/cloud-tasks',
      icon: 'stats-chart',
    },
    {
      title: 'Payments',
      url: '/payments',
      icon: 'cash',
    }
  ]

  constructor(@Inject('environment') public environment: any) {
    // called to fake content loading process
    // needed to allow loading of some resources that wait on content loading in portal
    this.contentService.publishLoaded() 
  }

  processMenuClick(item: any) {
    if (item.click) {
      item.click()
    }
    else {
      this.router.navigate([item.url || '/home'])
    }
  }

  async clickMore() {
    const buttons = this.morePages.map(p => (
      {
        text: p.title,
        handler: () => {
          this.router.navigate([ p.url || '/home' ])
        }
      }
    ))
    await this.utilityService.showActionSheet('More', buttons)
  }
}
