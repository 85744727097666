import { Observable } from "rxjs";

export interface ColSpec {
  key: string;
  label?: string;
  noSort?: boolean;
  currency?: boolean;
  timestamp?: boolean;
  timestamp_in_ms?: boolean;
  timestamp_in_ms_utc?: boolean;
  dateFormat?: string;
  truncated?: boolean;
  checkbox?: boolean;
  json?: boolean;
  markdown?: boolean;
  style?: string;
  icon?: string;
  fxHide?: string;
  valuefunc?: (val, row) => string;
  linkToFunc?: (row) => any;
  browseToFunc?: (val, row) => any;
  displayIf?: (context) => boolean;
  menuButtons?: MenuButton[];  
}

export interface MenuButton {
  label?: string;
  labelfunc?: (item) => string;
  displayFunc?: (item) => boolean;
  action: (item) => void;
}

export interface TableSpec {
  inspectRow?: boolean;
  columns: ColSpec[];
  rowStyle?: (item) => string;
  columnStyle?: string;
  sortingDataAccessor?: (item, property) => string;
  noPaginator?: boolean;
  pageSizeOptions?: number[];
}

export interface SourceSpec {
  path: Observable<string>;
  sortKey?: any;
  sortDirection?: 'asc' | 'desc';
  filterKey?: string;
  filterDescription?: string;
}
