import { Inject, Injectable } from '@angular/core'
import Handlebars from 'handlebars/dist/cjs/handlebars'
//const { Handlebars.SafeString, handlebars.escapeExpression } = Handlebars
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ExtractService, ProgramSpec, StatsKeys, formatDate, generateSatisfactionInsightsSummary, splitTrim } from '@cheaseed/node-utils'
import { AudioService } from './audio.service'
import { ChatStateService } from './chat-state.service'
import { ContentService } from './content.service'
import { EntryService } from './entry.service'
import { LearnService } from './learn.service'
import { ProgramService } from './program.service'
import { SharedEventService } from './shared-event.service'
import { SharedUserService } from './shared-user.service'
import { PointsService } from './points.service'
import { PromptService } from './prompt.service'
import { HandlebarsBase } from '@cheaseed/node-utils'
import { GroupService } from './group.service'


@Injectable({
  providedIn: 'root'
})
export class HandlebarsService extends HandlebarsBase {
  /** @internal */
  helpers: any = {}

  /** @internal */
  constructor(
    @Inject('ExtractService') private extractService: ExtractService,
    @Inject('UtilityService') private utilityService: any,
    private userService: SharedUserService,
    private contentService: ContentService,
    private chatStateService: ChatStateService, 
    private eventService: SharedEventService,
    private pointsService: PointsService,
    private entryService: EntryService,
    private programService: ProgramService,
    private promptService: PromptService,
    private learnService: LearnService,
    private groupService: GroupService,
    private audio: AudioService,
    private sanitizer: DomSanitizer
  ) {
    super()
    //has to be done later as contentService is not initialized 
    // during the super() call which creates the other helpers
    this.initialize()
    this.helpers.global = this.contentService.globals

  }
  initialize() {
    super.initializeHelpers(this.getHelpers())
    super.registerHandlebarHelpers(this.getHandlebarHelpers())
  }

  chatSummary(name: string) {
    const spec = this.contentService.getConversationNamed(name)
    return new Handlebars.SafeString(spec?.summary || '')
  }

  image(name: string) {
    // console.log("image helper", name)
    const url = name.startsWith('http') ? name : this.contentService.getMedia(name)
    const html = `<img src="${url || 'unknown'}">`
    return new Handlebars.SafeString(html)
  }

  imagewidth(name: string, width: number) {
    // console.log("imagewidth helper", name)
    const url = name.startsWith('http') ? name : this.contentService.getMedia(name)
    const html = `<img src="${url || 'unknown'}" width="${width}">`
    return new Handlebars.SafeString(html)
  }

  isChatCompleted(name: string): boolean {
    return this.chatStateService.isChatCompleted(name)
  }

  isUserAuthenticated(): boolean {
    return !this.userService.isAnonymous()
  }

  isUserGroupMember() {
    return !!this.groupService.currentUserGroup()
  }

  isUserPrepaidGroupMember() {
    const group = this.groupService.currentUserGroup()
    return group?.pricingPlan === 'prepaid'
  }

  isUserAffiliateGroupMember() {
    const group = this.groupService.currentUserGroup()
    return group?.pricingPlan === 'affiliate'
  }

  isChatPurchased(name: string): boolean {
    return this.chatStateService.isChatPurchased(name)
  }

  titleOfChatNamed(name: string): string {
    const chat = this.contentService.conversationMap?.get(name)
    return chat?.title as string
  }

 
  titleOfSeriesNamed(name: string): string {
    const series = this.contentService.pathMap.get(name)
    return series?.title
  }

  isCurrentProgram(name: string): boolean {
    return this.programService.getCurrentProgramName() === name
  }

  currentProgramCreateDate(): string {
    return formatDate(this.programService.getCurrentProgramCreatedAt())
  }

  formatDate(key:string, format:string) : string {
    key = Handlebars.escapeExpression(key)
    format = Handlebars.escapeExpression(format)
    const val = this.lookupAttribute(key)
    console.log("formatDate", key, format, val)
    return formatDate(val, format)
  }

  getLastEntryAttribute(attribute: string) {
    // Retrieve first entry from recent history
    const entry = this.entryService.getLastEntry()
    return entry?.attributes[attribute]
  }

  titleOfProgramNamed(name: string): string {
    const spec: ProgramSpec | undefined= this.contentService.programMap.get(name)
    return spec?.title as string
  }

  getLearnState(): string {
    return this.learnService.getLearnStateDefault()
  }

  playAudio(name: string) {
    if (this.userService.isSoundEnabled()) {
      const url = this.contentService.getMedia(name)
      if (url) {
        this.audio.preload(name, url)
        setTimeout(() => { this.audio.play(name) }, 300)
      }
    }
    return new Handlebars.SafeString('')
  }

  getCurrentStreak(): number {
    const streak: any = this.pointsService.getCurrentStreak()
    return streak.streak
  }

  //override
  numEntriesCreatedSince(type: string, unit: string, n: number): number {
    return this.entryService.numEntriesCreatedSince(type, unit, n)
  }

  //override
  numEntriesWithAttributeDateSince(type: string, attrName: string, sinceDate: string): number {
    return this.entryService.listEntriesWithAttributeDateSince(type, attrName, sinceDate).length
  }

  getReviewReadyStatus() {
    return this.userService.getUserKey(StatsKeys.ReviewReadyStatusKey)
  }

  hasFavorites() {
    return this.entryService.hasFavorites()
  }

  //override
  hasEntriesOfType(type: string): boolean {
    return this.getEntriesOfType(type === 'null' ? null : type)?.length > 0
  }

  extractChatSummaries(tags: any) {
    if (tags) {
      const str = typeof tags === 'string' ? tags : tags.string
      const tagList = splitTrim(str)
      // retrieve chats that match any of the tags
      const chats = this.contentService.conversations.filter(c => c.globalTags?.map(g => g.name).some(g => tagList.includes(g)))
      console.log(`extractChatSummaries found ${chats.length} distinct chats`, chats, str)
      // retrieve nun-null summaries
      const summaries = chats.map(c => `Title: ${c.title}\n\nTags: ${c.globalTags?.map(t => t.name).join(', ')}\n\nSummary: ${c.summary}\n\n`).filter(s => s)
      const result = summaries.length > 0 ? summaries.join('\n\n') : `No chats found matching tags: ${str}`
      console.log(`extractChatSummaries returning ${result.length} characters`)
      return result
    }
    else
      return ''
  }

  //Override
  hasPersona(name: string) {
    return this.userService.userHasPersona(name)
  }

  /** @internal */
  // Compile Markdown and sanitize
  //Override
  renderMarkdown(text: string) {
    const block = super.renderMarkdown(text) as string
    return block ? this.sanitizer.bypassSecurityTrustHtml(block) : null
  }

  /** @internal */
  //Override
  formatWithUserKeys(content: string, noRendering = false) {
    return this.format(content, this.userService.getCache(), noRendering)
  }

  /** @internal */
  format(content: string, cache: any = {}, noRendering = false, logError = true): SafeHtml | string | null {
    const block = super.format(content, cache, noRendering, logError)
    if (!block)
      return null
    // console.log("about to call marked with", noMarkdown, block)
    return noRendering
      ? block
      : this.renderBlock(block as string)
  }

  renderBlock(block: any) {
    // If the block starts with a HTML, assume there is no markdown required
    // console.log("renderBlock", block)
    const noMarkdown = typeof block === 'string' && block.startsWith("HTML")
    if (noMarkdown)
        block = block.substring(4)    
    return noMarkdown
      ? this.sanitizer.bypassSecurityTrustHtml(block)
      : typeof block === 'object'
        ? block
        : this.renderMarkdown(block)
  }

  //Overrides
  /** @internal */
  lookupAttributeSpec(key: string) {
    return this.contentService.getAttributeSpec(key)
  }

  /** @internal */
  // TODO: unify lookupAttributeSpec and getAttributeSpec
  getAttributeSpec(attr:string) {
    return this.lookupAttributeSpec(attr)
  }

  /** @internal */
  getGlobal(key: string) {
    return this.contentService.getGlobal(key)
  }

  /** @internal */
  getGlobalsStartingWith(key: string) {
    return this.contentService.getGlobalsStartingWith(key)
  }

  /** @internal */
  getUserKey(key: string) {
    return this.userService.getUserKey(key)
  }

  /** @internal */
  getUserKeysStartingWith(prefix: string) {
    return this.userService.getUserKeysStartingWith(prefix)
  }

  /** @internal */
  getPromptSpecNamed(name: string) {
    return this.promptService.getPromptSpecNamed(name)
  }

  /** @internal */
  generateTimestamp() {
    const now = new Date()
    return formatDate(now, 'MMM dd, yyyy')
  }

  /** @internal */
  getPromptGuides() {
    return this.contentService.promptGuides
  }

  extractTextFrom(key: string, heading: string) {
    const content = this.getUserKey(key)
    return this.extractService.extractTextFrom(key, content, heading)
  }

  extractListFrom(key: string, heading: string) {
    const content = this.getUserKey(key)
    if (typeof content !== 'string') {
      console.warn(`extractListFrom ${key} content is not a string, but instead is ${typeof content}`)
      return []
    }
    const result = this.extractService.extractListFrom(key, content, heading)
    return result.map((item:any, index:number) => ({ name: `${index + 1}`, description: item.text }))
  }

  extractSublistFrom(key: string, heading: string, itemText: string) {
    const content = this.getUserKey(key)
    if (typeof content !== 'string') {
      console.warn(`extractSublistFrom ${key} content is not a string, but instead is ${typeof content}`)
      return []
    }
    const result = this.extractService.extractSublistFrom(key, content, heading, itemText)
    return result.map((item:any, index:number) => ({ name: `${index + 1}`, description: item.text }))
  }

  /** @internal  */
  getEntriesOfType(type: string | null) {
    return this.entryService.getEntriesOfType(type)
  }

  generateSatisfactionInsightsSummary() {
    return generateSatisfactionInsightsSummary(
      this.entryService.getEntriesOfType(),
      this)
  }

  generateEntriesAsReportObjects(type: string, max: number, attributeName: string | null, sinceDate: string): any {
    return this.entryService.generateEntriesAsReportObjects(type, max, attributeName, sinceDate)
  }

  getHelpers() {
    return {
      ...super.getHelpers(),
      userService: this.userService,
      contentService: this.contentService,
      chatStateService: this.chatStateService,
      programService: this.programService,
      eventService: this.eventService,
      pointsService: this.pointsService,
      entryService: this.entryService,
      promptService: this.promptService,
      utilityService: this.utilityService,
      extractService: this.extractService,
      groupService: this.groupService,
      audio: this.audio
    }
  }

  getHandlebarHelpers() {
    return {
      ...super.getHandlebarHelpers(),
      image: this.image,
      imagewidth: this.imagewidth,
      isChatCompleted: this.isChatCompleted,
      titleOfChatNamed: this.titleOfChatNamed,
      titleOfSeriesNamed: this.titleOfSeriesNamed,
      isCurrentProgram: this.isCurrentProgram,
      currentProgramCreateDate: this.currentProgramCreateDate,
      titleOfProgramNamed: this.titleOfProgramNamed,
      getCurrentStreak: this.getCurrentStreak,
      playAudio: this.playAudio,
      hasFavorites: this.hasFavorites,
      getLearnState: this.getLearnState,
      getLastEntryAttribute: this.getLastEntryAttribute,
      chatSummary: this.chatSummary,
      extractChatSummaries: this.extractChatSummaries,
      getAttributeSpec: this.getAttributeSpec
    }
  }
}
