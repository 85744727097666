/**  Uniform logging interface used by code used by both cloud 
 * functions and apps. Pass implementors of this interface
 * to the plain typescript class via a function call or 
 * constructor
*/
export interface LoggerInterface {
    log(...args: any[]): void
    debug(...args: any[]): void
    warn(...args: any[]): void
    error(...args: any[]): void
}