import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { SETTINGS } from '@angular/fire/compat/firestore'
import { connectFirestoreEmulator, initializeFirestore, provideFirestore } from '@angular/fire/firestore'
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { environment } from '../environments/environment';
import { BuildTag } from 'buildtag';
import { ReleaseTag, PatchLevel } from 'releasetag';
import { AirtableBase, ExtractService } from '@cheaseed/node-utils';
import { UtilityService } from '@cheaseed/cheaseed-core';

@NgModule({ 
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        BrowserAnimationsModule
    ],
    providers: [
        CleverTap,
        SocialSharing,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9098', { disableWarnings: true });
            }
            return auth;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideFunctions(() => {
            const functions = getFunctions();
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            return functions;
        }),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } },
        { provide: 'environment', useValue: { ...environment, buildTag: BuildTag, releaseTag: ReleaseTag, patchLevel: PatchLevel } },
        { provide: 'AirtableService', useClass: AirtableBase },
        { provide: 'ExtractService', useClass: ExtractService },
        { provide: 'UtilityService', useClass: UtilityService },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
