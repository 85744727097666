import { Inject, Injectable, signal } from "@angular/core"

const AIRTABLESCHEMA_KEY = 'AirtableSchema'
const AIRTABLESYNC_TIME_KEY = `AirtableSync-time`

@Injectable({
  providedIn: 'root'
})
export class AirtableHelper {

    private baseId: string
    private apiKey: string
    private idMap: Map<string, any> = new Map() // All retrieved records, indexed by id
    private tableMap: Map<string, any> = new Map()
    private contentMap: Map<string, any> = new Map()

    isLive = signal(false)

    constructor(@Inject('environment') private environment: any) {
        this.baseId = this.environment.airtable.base
        this.apiKey = this.environment.airtable.accessToken
    }

    getFetchHeaders() {
        return { 
            'Authorization': `Bearer ${this.apiKey}`, 
            'Content-Type': 'application/json' 
        }
    }

    async loadSchema() {
        const url = `https://api.airtable.com/v0/meta/bases/${this.baseId}/tables`
        const schema = await this.queryAirtable(url)
        this.tableMap = new Map(schema.tables.map((t:any) => [ t.name, t ]))
        console.log('AirtableHelper', `Loaded schema with ${this.tableMap.size} tables`)
        localStorage.setItem(AIRTABLESCHEMA_KEY, JSON.stringify(schema))
        this.idMap = new Map()
        this.isLive.set(true)
    }

    restoreSchema() {
        this.isLive.set(true)
        const schemas = localStorage.getItem(AIRTABLESCHEMA_KEY)
        this.tableMap = new Map(JSON.parse(schemas).tables.map((t:any) => [ t.name, t ]))
    }

    getTable(tableName: string) {
        const table = this.tableMap.get(tableName)
        if (!table)
            throw new Error(`Table ${tableName} not found in schema`)
        return table
    }

    async queryAirtable(url: string) {
        const headers = this.getFetchHeaders()
        const response = await fetch(url, { headers })
        const json = await response.json()
        // console.log(url)
        return json
    }

    async getTableContents(tableName: string, filter?: string): Promise<[ Map<string, any>, any[] ]> {
        const recordMap = new Map<string, any>()
        const table = this.getTable(tableName)
        const url = `https://api.airtable.com/v0/${this.baseId}/${table.id}${filter ? `?filterByFormula=${filter}` : ''}`
        let json:any = await this.queryAirtable(url)
        json.records.forEach((rec:any) => recordMap.set(rec.id, rec.fields))
        while (json.offset) {
            json = await this.queryAirtable(`${url}${filter ? '&' : '?'}offset=${json.offset}`)
            json.records.forEach((rec:any) => recordMap.set(rec.id, rec.fields))
        }
        console.log('AirtableHelper', `Retrieved ${recordMap.size} ${tableName} records`)
        const nameMap = new Map<string, any>([ ...recordMap.values() ].map((rec:any) => [ rec.Name, rec ]))
        this.contentMap.set(tableName, nameMap)
        const values = Array.from(nameMap.values())
        try {
            localStorage.setItem(tableName, JSON.stringify(values))
        }
        catch (err:any) {
            console.error(err)
        }
        return [ nameMap, values ]
    }

    getLocalStorage(tableName: string) {
        console.log("Retrieving AirtableSync data from local storage", tableName)
        const data = localStorage.getItem(tableName)
        return data ? JSON.parse(data) : null
    }

    setAirtableSyncTimestamp() {
        localStorage.setItem(AIRTABLESYNC_TIME_KEY, Date.now().toString())
    }

    isAirtableSyncMoreCurrentThan(msecs: number) {
        const last = localStorage.getItem(AIRTABLESYNC_TIME_KEY)
        if (last) {
            const airTime = parseInt(last)
            console.log(`Comparing last AirtableSync time ${airTime} to ${msecs}`)
            const result = airTime > msecs
            return result
        }
        return false
    }
}
