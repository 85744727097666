export interface CheaseedEnvironment {
    buildTag?: string;
    releaseTag?: string;
    patchLevel?: number;
    appSource: AppSource;
    production: boolean;
    metaPixelId: string;
    gtmId: string;
    devPwd: string;
    useEmulators: boolean;
    portalHost: string;
    portalGiftLink: string;
    linkHost: string;
    firebase: any;
    clevertap: any;
    airtable?: any;
    branch: any;
    azure: any;
    globalErrors: any;
    appleClientId: string;
    googleWebClientId: string;
    androidRevenueCatKey: string;
    iosRevenueCatKey: string;
    }

// Used to look up terminology based on EntrySpec type
// If not present, then use type as singular and type+s as plural
export const TypeTermMap = {
    // Singular and plural values now modeled in Gloabls
    "Playlist": "My List",
    "UpNext": "Up Next",
    "Entries": "Entries",
}

export const getTermsForType = (type: string) => {
    return TypeTermMap[type] || { singular: type, plural: type + 's' }
}

export const getTypeForTerm = (term: string) => {
    // reverse lookup
    for (const type of Object.keys(TypeTermMap)) {
        if (TypeTermMap[type].singular === term || TypeTermMap[type] === term)
            return type
    }
    return null
}

export interface FilterChip {
    type: string;
    title?: string;
    admin?: boolean;
}

export const StatsKeys = {
    ReviewStartDateUserKey: "ReviewConfig.reviewStartDate",
    ReviewEndDateUserKey: "ReviewConfig.reviewEndDate",
    ReviewAccompsPerWeek: "ReviewConfig.reviewAccompsPerWeek",
    ReviewTargetNumAccomplishments: "user.targetNumAccomplishments",
    ReviewReadyStatusKey: "user.reviewReadyStatus",
    SatisfactionStartDate: "StatsConfig.satisfactionStartDate",
    MarketSavvyStartDate: "StatsConfig.marketStartDate",
    PitchFreshnessPeriod: "StatsConfig.pitchPeriod",
    NetworkStartDate: "NetworkStatsConfig.networkStartDate",
    NetworkEndDate: "NetworkStatsConfig.networkEndDate",
    NetworkTargetLevel: "NetworkStatsConfig.targetNetwork",
    NetworkStatusKey: "user.networkLeadStatus",
    NetworkTargetNumLeadsKey: "user.targetNumLeads",
    PitchStartDate: "PitchStatsConfig.pitchStartDate",
    PitchEndDate: "PitchStatsConfig.pitchEndDate",
    PitchTargetNumPitchesKey: "user.targetNumPitches",
    PitchTargetLevel: "PitchStatsConfig.targetPitches",
    PitchStatusKey: "user.pitchStatus",
    AskStartDate: "AskStatsConfig.askStartDate",
    AskEndDate: "AskStatsConfig.askEndDate",
    AskTargetNumAsksKey: "user.targetNumAsks",
    AskTargetLevel: "AskStatsConfig.targetAsks",
    AskStatusKey: "user.askStatus"
}

// Network Leads per week by level
export const NetworkLevelFactors = {
    "level.low": 0.25,
    "levelMedDefault": 1,
    "level.high": 3
}

// Pitches per week by level
export const PitchLevelFactors = {
    "level.low": 0.5,
    "levelMedDefault": 1,
    "level.high": 2
}

// Asks per month by level
export const AskLevelFactors = {
    "level.low": 0.333,
    "levelMedDefault": 1,
    "level.high": 2
}

export const HIDDEN = "Hidden"
export const UNPINNABLE = "Unpinnable"
export const FirstChatKey = "Onboarding.chat"
export const HOMEURL = "/tabs/home"
export const GUIDESHARE_MSG = "guideshare.message"
export const SCRIPTSHARE_MSG = "scriptshare.message"
export const CHATSHARE_MSG = "chatshare.message"
export const STATEMENTSHARE_MSG = "statementshare.message"
export const CHALLENGESHARE_MSG = "challengeshare.message"
export const SERIES_COMPLETION_STATUS_KEY = "user.seriesCompletionStatuses"
export const TILESHARE_MSG = "tileshare.message"
export const ENTRYSHARE_MSG = "entryshare.message"
export const PARTICIPANTSHARE_MSG = "participantshare.message"
export const USER_PERSONAS_KEY = 'user.personas'
export const USER_RECOMMENDED_CHATS_KEY = 'user.recommendedChats'
export const USER_LAST_CHAT_ID = 'user.lastChatId'
export const USER_CURRENT_CHAT_NAME = 'user.currentChatName'

// Values must be synced with app environment.ts files
export enum AppSource {
    App = "app",
    Web = "web",
    Admin = "admin",
    Portal = "portal"
  }

// date-fns format string: https://date-fns.org/v2.29.3/docs/format
export const TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mmXX'
export const TIMESTAMP_SECS_FORMAT = 'yyyy-MM-dd HH:mm:ssXX'
// Note the single quoted 'T' in the format below 
export const TIMESTAMP_ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXX"
export const TIMESTAMP_MILLIS_ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXX"
export const TIMESTAMP_MILLIS_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSSXX'
export const MMDDYYYY_FORMAT = 'MM/dd/yyyy'
export const YYYYMMDD_FORMAT = 'yyyy-MM-dd'
export const YYYYMMDDHHmm_FORMAT = 'yyyyMMdd-HHmm'
export const MMMDDYYYY_FORMAT = 'MMM dd, YYYY'

export const DEFAULT_SEED_BALANCE = 100
export const DEFAULT_SEED_BALANCE_KEY = 'default.seedBalance'
export const STRIPE_TRANSACTION_IDENTIFIER = 'WEB'
export const STRIPE_CANCELLED_TRANSACTION_IDENTIFIER = 'CANCELLED'
export const STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER = 'PAYMENT_IN_PROGRESS'
export const STRIPE_TRANSACTION_FAILED_IDENTIFIER = 'TRANSACTION_FAILED'
export const STRIPE_PAYMENT_PENDING_TRANSACTION_IDENTIFIER = 'PAYMENT_PENDING'
export const STRIPE_NOPAY_TRANSACTION_IDENTIFIER = 'NO_PAYMENT_SUCCESS'

export const BEHAVIOR_RESPONSE_REQUIRED = 'ResponseRequired'
export const BEHAVIOR_FAVORITABLE = 'Favoritable'
export const BEHAVIOR_COLLECTIBLE = 'Collectible'
export const BEHAVIOR_READ_ONLY_REPORT_TEMPLATE = 'ReadOnlyReportTemplate'
export const BEHAVIOR_USE_LONG_REPORT_CARD_STYLE = 'UseLongReportCardStyle'
export const BEHAVIOR_START_PROMPT_CHAIN = 'StartPromptChain'
export const BEHAVIOR_CONTINUE_PROMPT_CHAIN = 'ContinuePromptChain'
export const BEHAVIOR_WAIT_FOR_PROMPT_RESPONSE = 'WaitForPromptResponse'
export const BEHAVIOR_CHARGE_IF_UNSTARTED = 'ChargeIfUnstarted'
export const BEHAVIOR_DOES_NOT_REQUIRE_SEEDS = 'DoesNotRequireSeeds'
export const BEHAVIOR_SKIP_COMPLETION_CARD = 'SkipCompletionCard'
export const BEHAVIOR_DO_NOT_TRACK = 'DoNotTrack'
export const BEHAVIOR_DO_NOT_UPDATE_CHAT_QUEUES = 'DoNotUpdateChatQueues'
export const BEHAVIOR_DO_NOT_SHARE = 'DoNotShare'
export const BEHAVIOR_CLEAR_CURRENT_VALUE = 'clearCurrentValue'
export const BEHAVIOR_DEFAULT_TO_LAST_USED_VALUE = 'defaultToLastUsedValue'
export const BEHAVIOR_ROUTE_TO_HOME_ON_COMPLETION = 'RouteToHomeOnCompletion'
export const BEHAVIOR_ROUTE_TO_TRACK_ON_COMPLETION = 'RouteToTrackOnCompletion'
export const BEHAVIOR_USE_GENERATED_OPTIONS = 'useGeneratedOptions'
export const BEHAVIOR_CLEARABLE = 'clearable'
export const BEHAVIOR_PREVENT_BACK_NAVIGATION = 'PreventBackNavigation'
export const BEHAVIOR_REQUIRE_AUTHENTICATION = 'RequireAuthentication'
export const BEHAVIOR_ALLOW_AUTHENTICATION = 'AllowAuthentication'
export const BEHAVIOR_REQUIRE_PAYMENT = 'RequirePayment'
export const BEHAVIOR_FOR_ADMIN_USER_ONLY = 'ForAdminUserOnly'
export const BEHAVIOR_USER_NEXT_TO_ADVANCE = 'useNextToAdvance'
export const BEHAVIOR_ALLOW_WEB_MICROPHONE_ENTRY = 'allowWebMicrophoneEntry'

export const ANONYMOUS_USER_ID = 'anonymousUserId'

export const DEFAULT_OPENAI_MODEL_KEY = 'default.openai.model'
export const DEFAULT_CHAT_SEED_COST_KEY = 'default.seedCost.chat'
export const DEFAULT_CHALLENGE_SEED_COST_KEY = 'default.seedCost.challenge'
export const SEEDCOST_CHAT_MESSAGE_KEY = 'seedCost.chat.message'
export const ADVICE_CHATS_KEY = 'advice.chats'
export const TRACKER_REPORTS_SPECS = 'tracker.reports.specs'
export const COLLECTION_ENTRIES_ATTRIBUTE_NAME = 'entries'

export const DEFAULT_OPENAI_TIMEOUT = 540000 // 9 minutes to get one response from OpenAI

// The client timeout is in milliseconds and the cloud function
// timeout specification is in seconds. Keeping both here so that we know
export const OPENAI_CLOUD_FUNCTION_TIME_OUT = 540 // 9 minutes (max), in seconds
export const OPENAI_CLOUD_FUNCTION_CLIENT_TIME_OUT = OPENAI_CLOUD_FUNCTION_TIME_OUT * 1000   // in milliseconds

// Both keys work
// const OPENAI_API_KEY = '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';
//export const OPENAI_API_KEY = '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'

export const OPENAI_API_KEY = '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'

// created in cheaseed-east-us
export const OPENAI_ASSISTANT_KEY = 'asst_i1UAjrBUTdzVMW9ePfvmS4MF'
export const OPENAI_ASSISTANT_KEY_PROD = 'asst_Bu0t9ZPgJwOh8j1hmqMPJ9Ig'
export function getAzureOpenAIAssistantId(env: string) {
    return env === 'prod' ? OPENAI_ASSISTANT_KEY_PROD : OPENAI_ASSISTANT_KEY
}

// see https://openai.com/pricing

export const CHATGPT_COSTS = { 
    // 8K context
    'gpt-4': {
        input: 0.03,
        output: 0.06
    },
    // 128K context
    'gpt-4-1106-preview': {
        input: 0.01,
        output: 0.03
    },
    // 128K context
    'gpt-4o': {
        input: 0.005,
        output: 0.015
    },
    // 128K context
    'gpt-4o-mini': {
        input: 0.000165,
        output: 0.00066
    },
    // 16K context
    'gpt-3.5-turbo-16k': {
        input: 0.003,
        output: 0.004
    }
  }

// TODO Idea: Implement failover logic: When a server fails (e.g. fetch error), cycle to next server and retry there
// Current issue is that cheaseed-west can't currently deploy to gpt-4 default (01/20/2004), so this would only work for gpt-4-1106-preview
// 2024-04-26 Added australia-east as a fallback for all
// 2024-02-27 - added env attribute indicating which server to use in a given env (prod or dev)
// cheaseed-west is currently not used
export const AZURE_OPENAI_SERVERS: AzureServerConfig[] = [
    { env: 'prod', endpoint: 'https://cheaseed-east-us.openai.azure.com//', key: '06a73e721e6a47a585fa3c5ea3093355', 
        models: [ 'gpt-4o', 'gpt-4o-mini', 'gpt-35-turbo-16k'], defaultModel: 'gpt-4o'},
    { env: 'dev', endpoint: 'https://cheaseed-east-us.openai.azure.com//', key: '06a73e721e6a47a585fa3c5ea3093355', 
        models: [ 'gpt-4o', 'gpt-4o-mini', 'gpt-35-turbo-16k'], defaultModel: 'gpt-4o'},
    { env: 'prod-west', endpoint: 'https://cheaseed-west.openai.azure.com/', key: '95cf64e74a064c15a57ac23cd5811ef8', 
        models: [ 'gpt-4o', 'gpt-4-1106-preview', 'gpt-35-turbo-16k'], defaultModel: 'gpt-4o'},
    { env: 'dev-west', endpoint: 'https://cheaseed-west.openai.azure.com/', key: '95cf64e74a064c15a57ac23cd5811ef8', 
        models: [ 'gpt-4o', 'gpt-4-1106-preview', 'gpt-35-turbo-16k'], defaultModel: 'gpt-4o'},
    { env: 'none', endpoint: 'https://cheaseed-sweden.openai.azure.com/', key: '57f1198ef7c4424791193ca9c2420510', 
        models: [ 'gpt-4-turbo-2024-04-09', 'gpt-4-1106-preview', 'gpt-35-turbo-16k', 'gpt-4', 'gpt-4-32k' ] },
    { env: 'prod-aus', endpoint: 'https://cheaseed-east.openai.azure.com/', key: 'f0ff90bdb2d84ab58a515237f63c7f08', 
        models: [ 'gpt-4-1106-preview', 'gpt-35-turbo-16k', 'gpt-4' ] },
    { env: 'dev-aus', endpoint: 'https://cheaseed-australia.openai.azure.com/', key: '58587c75fdd54a6aa0de9b46e51d140b',
        models: [ 'gpt-4-1106-preview', 'gpt-35-turbo-16k', 'gpt-4' ] }
]

export const AZURE_DOCINTEL_SERVERS = [
    {
        env: 'dev',
        endpoint: 'https://westus2.api.cognitive.microsoft.com/',
        key1: '1cda5830c0cc4a4f8be9a9edda5d0df7',
        region: 'westus2'
    },
    {
        env: 'dev',
        endpoint: 'https://chea-doc-intel.cognitiveservices.azure.com/',
        key1: '09b6fe8a4aec4b4eb9fc33b7f77e20e8',
        key2: '6161919c808c4c8d83f7bf221bb3f24e',
        region: 'westus'
    }
]

export const MAX_CONTINUATIONS = 10
export const MAX_OPENAI_RETRIES = 10
export const AZURE_OPEN_AI = "OpenAI.isAzure"
export const GPT35 = 'gpt-3.5'
export const GPT4 = 'gpt-4'
export const OPENAI_35_MODEL_NAME = 'gpt-3.5-turbo-16k'
export const OPENAI_40_MODEL_NAME = 'gpt-4' // same across Azure and OpenAI
export const AZURE_OPENAI_35_MODEL_NAME = 'gpt-35-turbo-16k'
export const OPENAI_40_TURBO_MODEL_NAME = 'gpt-4-1106-preview' // same across Azure and OpenAI

//stripe
export const STRIPE_SECRET_KEY = "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"
export const STRIPE_TEST_SECRET_KEY = "sk_test_51OKZzvEzFwmkP79I2DBMpD4PFcpA30X1Wee2Nrqwmmhzku7NzoU1JHUD60WD31Za92SKrXhQVs9THi9McbzQoikH00pjrHtT74"
export const STRIPE_PUBLISHABLE_KEY = "pk_live_51OKZzvEzFwmkP79IQokXzYgIp3I0aVmZjWX0GAJOhHgE4woUaqEnSNqiGG7qFMzGQdbT1JbDNDHdOKHN3jgyBH6T00byax5rnW"
export const STRIPE_TEST_PUBLISHABLE_KEY = "pk_test_51OKZzvEzFwmkP79IXsxuQEVGTthJ5CvSKmB8DDU2qSi8BL6Fkh7ZMWqd2BgYI5779hkUZfmwjygOiByh8lhiTklM004AJqdiQX"
export const STRIPE_TEST_ENDPOINT_SECRET = "whsec_oCavNy2eNEyqxn05RTeqncehd6kYfQuk"
export const STRIPE_ENDPOINT_SECRET = "whsec_tv747g2Q4iLi1wbyGale74RMrgM9bWgw"
export const STRIPE_TEST_PAYMENT_CONFIG_ID = "pmc_1OX1vzEzFwmkP79IQuQcQW7j"
export const STRIPE_PAYMENT_CONFIG_ID = "pmc_1OX0btEzFwmkP79IMqsBQtrX"
export const STRIPE_TEST_PAYMENT_LINK_ENDPOINT_SECRET = "whsec_5vX5y7GVtYV0NPlZ3PbknTYHxzMp16U7"
export const STRIPE_PAYMENT_LINK_ENDPOINT_SECRET = "whsec_acfurb9mmP37lzYuN4Ns6dCaFffR1wOm"
export const GIFTS_COLLECTION = 'gifts'
export const GROUPS_COLLECTION = 'groups'
export const USERS_COLLECTION = 'users'
export const DEV_PORTAL_URL = 'https://portal-dev.cheaseed.com'
export const PORTAL_URL = 'https://portal.cheaseed.com'
export const ADMIN_CERTIFIED = 'cheaseed-admin-certified'

//groups
export const MAX_GROUP_NAME_LENGTH = 40
export const MIN_GROUP_NAME_LENGTH = 10
export const GROUP_LEDGER_COLLECTION_NAME = "groupLedger"
export const GROUP_MEMBERS_COLLECTION_NAME = "members"
export const GROUP_BALANCE_LOW = 200

export type UserKeyDoc = {
    value: unknown;
    key: string;
    isCloud?: boolean;
    createdAt?: string;
    updatedAt?: string;
  } 

  export function getStripeSecretKey(env: string) {
    // console.log('Env = ', env)
    return env === 'dev'
        ? STRIPE_TEST_SECRET_KEY
        : STRIPE_SECRET_KEY
}

export function getStripePublishableKey(env: string) {
    return env === 'dev' ?
        STRIPE_TEST_PUBLISHABLE_KEY
        : STRIPE_PUBLISHABLE_KEY
}

export function getStripeEndPointSecret(env: string) {
    return env === 'dev' ?
        STRIPE_TEST_ENDPOINT_SECRET
        : STRIPE_ENDPOINT_SECRET
}

export function getStripePaymentLinkEndPointSecret(env: string) {
    return env === "dev" ?
        STRIPE_TEST_PAYMENT_LINK_ENDPOINT_SECRET
        : STRIPE_PAYMENT_LINK_ENDPOINT_SECRET
}
export function getStripePaymentMethodConfigId(env: string) {
    return env === "dev" ?
        STRIPE_TEST_PAYMENT_CONFIG_ID
        : STRIPE_PAYMENT_CONFIG_ID
}

export function getPortalURL(env: string) {
    return env === "dev" ?
    DEV_PORTAL_URL
    : PORTAL_URL
}

export interface AzureServerConfig  {
    env: string,
    endpoint: string,
    key: string,
    models: string[],
    defaultModel?: string

}
export function getAzureOpenAIServer(env: "dev" | "prod"): AzureServerConfig {
    //console.log("Azure End Point lookup env", env)
    if (['dev', 'prod'].includes(env))
        return AZURE_OPENAI_SERVERS.find( server => server.env === env )
    else
        throw new Error('Internal Error: Invalid environment specified in Azure server lookup')    
}